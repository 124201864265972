import { configureStore } from '@reduxjs/toolkit'
import machinesReducer from './reducers/equipment'
import userReducer from './reducers/user'
import groupsReducer from './reducers/groups'
import sessionsReducer from './reducers/sessions'
import devicesReducer from './reducers/devices'

const store = configureStore({
    reducer: {
        equipment: machinesReducer,
        user: userReducer,
        groups: groupsReducer,
        sessions: sessionsReducer,
        devices: devicesReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
