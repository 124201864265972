import { useEffect, useState } from 'react'
import { SessionData, SoftwareItems } from '../../data/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'
import { useImplements, useMachines } from '../../data/reducers/equipment'

const useSessionDataHook = (sessionId: string) => {
    const machines = useMachines()
    const _implements = useImplements()

    const sessionListData = useSelector((state: RootState) => state.sessions.data)

    const [currentSoftware, setCurrentSoftware] = useState<SoftwareItems>()

    const [sessionsList, setSessionsList] = useState<SessionData[] | null>(null)

    useEffect(() => {
        setSessionsList(sessionListData)
    }, [sessionListData])

    useEffect(() => {
        if (sessionsList) {
            const session = sessionsList.find((session) => session.id === sessionId)

            const machine = machines.find((machine) => machine.id === session?.machine.id)
            const _implement = _implements.find(
                (_implement) => _implement.id === session?.implement.id
            )

            const softwareVersions = {
                machines: machine?.softwareVersions || [],
                _implements: _implement?.softwareVersions || [],
            }
            setCurrentSoftware(softwareVersions)
        }
    }, [machines, _implements, sessionId, sessionsList])

    return { currentSoftware }
}

export { useSessionDataHook }
