import { RootState } from '../data/store'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import SessionTiles from '../components/recordedSessions/sessionTiles'
import { ConfirmationPopup } from '../components/misc/popup'
import { useNetworkManager } from '../network/networkManager'
import { selectActiveSession } from '../data/reducers/sessions'
import SessionTile from '../components/recordedSessions/sessionTile'
import { Toolbar } from '../components/toolbar/toolbar'

function RecordedSessions() {
    const sessionsData = useSelector((state: RootState) => state.sessions.data)
    const location = useLocation()

    const [searchParams, setSearchParams] = useSearchParams()
    const selectedSessionId = searchParams.get('sessionSelected')
    const [sessions, setSessions] = useState(sessionsData)
    const [scrollToEvent, setScrollToEvent] = useState('')

    useEffect(() => {
        setSessions(sessionsData)
    }, [sessionsData])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const eventId = params.get('scrollToEvent')
        setScrollToEvent(eventId || '')
    }, [location.search])

    const handleSessionSelect = (sessionId: string) => {
        setSearchParams((prevParams) => {
            // Create a copy so we don’t mutate prevParams directly
            const newParams = new URLSearchParams(prevParams)

            if (sessionId === newParams.get('sessionSelected')) {
                newParams.delete('sessionSelected')
            } else {
                newParams.set('sessionSelected', sessionId)
            }

            return newParams
        })
    }

    const activeSession = useSelector(selectActiveSession)

    const { sessions: sessionsAPI } = useNetworkManager()
    const [isResumeDialogueVisible, setIsResumeDialogueVisible] = useState<boolean>(false)
    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sessionIdToResume, setSessionIdToResume] = useState<string | null>(null)
    const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(null)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [isDescendingSessionOrder, setIsDescendingSessionOrder] = useState<boolean>(true)
    const [isDescendingEventOrder, setIsDescendingEventOrder] = useState<boolean>(true)

    const resumeSession = async (sessionId: string) => {
        if (activeSession?.id) {
            alert(`Session ${activeSession.title} is already active!`)
            return
        }
        setSessionIdToResume(sessionId)
        setIsResumeDialogueVisible(true)
    }
    const deleteSession = async (sessionId: string) => {
        setSessionIdToDelete(sessionId)
        setIsDialogueVisible(true)
    }

    const resumeHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToResume) {
            setLoading(true)
            await sessionsAPI.resumeSession(sessionIdToResume)
            setLoading(false)
            setSessionIdToResume(null)
        }
        setIsResumeDialogueVisible(false)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToDelete) {
            setLoading(true)
            await sessionsAPI.deleteSession(sessionIdToDelete, true)
            setLoading(false)
            setSessionIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const selectedSession = sessions.find((session) => session.id === selectedSessionId) || null

    return (
        <div className="sessions-container-wrapper">
            <div className="sessions-container-flex">
                <div className="sessions-container">
                    <header>Previous Sessions</header>
                    <div className="sessions-list">
                        <SessionTiles
                            sessions={sessions}
                            onSelect={handleSessionSelect}
                            isDescendingSessionOrder={isDescendingEventOrder}
                            selectedSessionId={selectedSession?.id}
                            onResume={resumeSession}
                            onDelete={deleteSession}
                        />
                    </div>

                    {isResumeDialogueVisible && (
                        <ConfirmationPopup
                            ChildComponent={
                                <div>Are you sure you want to resume this session?</div>
                            }
                            callback={resumeHandler}
                            loading={loading}
                        />
                    )}
                    {isDialogueVisible && (
                        <ConfirmationPopup
                            ChildComponent={
                                <div>Are you sure you want to delete this session?</div>
                            }
                            callback={deleteHandler}
                            loading={loading}
                        />
                    )}

                    {selectedSession ? (
                        <>
                            <Toolbar
                                tools={[
                                    {
                                        label: 'Descending Session Order',
                                        data: {
                                            enabled: isDescendingSessionOrder,
                                            setEnabled: setIsDescendingSessionOrder,
                                        },
                                    },
                                    {
                                        label: 'Descending Event Order',
                                        data: {
                                            enabled: isDescendingEventOrder,
                                            setEnabled: setIsDescendingEventOrder,
                                        },
                                    },
                                    {
                                        label: 'Local time',
                                        data: {
                                            enabled: isLocalTime,
                                            setEnabled: setIsLocalTime,
                                        },
                                    },
                                ]}
                            />

                            <SessionTile
                                session={selectedSession}
                                onResume={selectedSession?.id ? undefined : resumeSession}
                                isUtc={!isLocalTime}
                                isDescending={isDescendingEventOrder}
                                scrollToEvent={scrollToEvent}
                                displayEventList={true}
                                showSessionTools={false}
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default RecordedSessions
