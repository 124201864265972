import { FC } from 'react'
import './loadingScreen.css' // Import the CSS file

export const LoadingScreen: FC = () => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <img
                    src="/Case_IH_Variant_Flat_COLOR_RGB_TransparentBackground.png"
                    alt="Loading..."
                    className="tractor-image"
                />
                <p className="loading-text">Loading...</p>
            </div>
        </div>
    )
}
