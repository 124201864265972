import { AppDispatch } from '../../data/store'
import {
    fetchDataRequest,
    fetchDataFailure,
    fetchDevicesSuccess,
    updateDeviceStatus,
    deleteDeviceAction,
} from '../../data/reducers/devices'
import { ApolloClient, NormalizedCacheObject, gql, ApolloError } from '@apollo/client'

export const fetchDevices = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_DEVICES = gql`
        query GetDevices {
            devices {
                id
                name
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                images {
                    id
                    friendlyName
                    url
                    thumbnailUrl
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_DEVICES,
        })

        dispatch(fetchDevicesSuccess(response.data.devices))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const updateDevice = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    name: string,
    description: string
) => {
    const UPDATE_DEVICE = gql`
        mutation UpdateDevice($id: ID!, $name: String, $description: String) {
            updateDevice(id: $id, name: $name, description: $description) {
                id
                name
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: UPDATE_DEVICE,
            variables: { id, name, description },
        })
        // Optionally, refetch all devices or update the specific device in the Redux store
        const updatedDevice = response.data.updateDevice
        dispatch(updateDeviceStatus(updatedDevice))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteDeviceFile = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    fileId: string
) => {
    const DELETE_DEVICE_FILE = gql`
        mutation DeleteDeviceFile($id: ID!, $fileId: ID!) {
            deleteDeviceFile(id: $id, fileId: $fileId) {
                id
                name
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_DEVICE_FILE,
            variables: { id, fileId },
        })

        const updatedDevice = response.data.deleteDeviceFile
        dispatch(updateDeviceStatus(updatedDevice))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteDeviceImage = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    fileId: string
) => {
    const DELETE_DEVICE_IMAGE = gql`
        mutation DeleteDeviceImage($id: ID!, $fileId: ID!) {
            deleteDeviceImage(id: $id, fileId: $fileId) {
                id
                name
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                images {
                    id
                    friendlyName
                    url
                    thumbnailUrl
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_DEVICE_IMAGE,
            variables: { id, fileId },
        })

        const updatedDevice = response.data.deleteDeviceImage
        dispatch(updateDeviceStatus(updatedDevice))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const downloadDeviceFile = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    fileId: string
) => {
    const GET_DEVICE_FILE_DOWNLOAD_URL = gql`
        query GetDeviceFileDownloadUrl($id: ID!, $fileId: ID!) {
            getDeviceFileDownloadUrl(id: $id, fileId: $fileId) {
                url
                expiresAt
            }
        }
    `

    try {
        const response = await client.query({
            query: GET_DEVICE_FILE_DOWNLOAD_URL,
            variables: { id, fileId },
        })

        const { url } = response.data.getDeviceFileDownloadUrl

        // Use the URL to trigger a the file download
        window.location.href = url
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const commandDevice = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    capability: string,
    payload: any
) => {
    const COMMAND_DEVICE = gql`
        mutation CommandDevice($id: ID!, $capability: String!, $payload: JSON!) {
            commandDevice(id: $id, capability: $capability, payload: $payload)
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: COMMAND_DEVICE,
            variables: { id, capability, payload },
        })

        if (response.data.commandDevice) {
            console.log(`Command ${capability} sent successfully to device ${id}`)
        } else {
            throw new Error('Command execution failed on the server')
        }
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteDevice = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string
) => {
    const DELETE_DEVICE = gql`
        mutation DeleteDevice($id: ID!) {
            deleteDevice(id: $id)
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_DEVICE,
            variables: { id },
        })

        if (response.data.deleteDevice) {
            // Dispatch action to remove device from store
            dispatch(deleteDeviceAction({ id }))
        } else {
            throw new Error('Device deletion failed on the server')
        }
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const getAllDevices = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_ALL_DEVICES = gql`
        query GetAllDevices {
            getAllDevices {
                id
                name
                groups {
                    id
                    name
                }
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                images {
                    id
                    friendlyName
                    url
                    thumbnailUrl
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_ALL_DEVICES,
        })
        dispatch(fetchDevicesSuccess(response.data.getAllDevices))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const editDeviceGroups = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    deviceId: string,
    groupIds: string[]
) => {
    const EDIT_GROUPS = gql`
        mutation EditGroups($deviceId: ID!, $groupIds: [ID!]!) {
            editGroups(deviceId: $deviceId, groupIds: $groupIds) {
                id
                name
                groups {
                    id
                    name
                }
                equipment
                description
                capabilities
                deviceId
                connectedAt
                isConnected
                disconnectedAt
                files {
                    id
                    friendlyName
                    fileSize
                    dateCreated
                    S3UploadProgress
                }
                isExecutingCommand
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: EDIT_GROUPS,
            variables: { deviceId, groupIds },
        })

        // The updated device object returned by the mutation
        const updatedDevice = response.data?.editGroups

        // Optionally, update Redux state with the updated device
        if (updatedDevice) {
            dispatch(updateDeviceStatus(updatedDevice))
        }
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}
