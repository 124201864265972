import { useState, FC, useRef, useLayoutEffect, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'

import { useNetworkManager } from '../../network/networkManager'
import { useSessionDataHook } from '../helpers/sessionDataHelpers'
import { ConfirmationPopup } from '../misc/popup'

interface SessionConfigurationToolbarProps {
    sessionId: string
    notifyStopSuccess: () => void
}

const SessionConfigurationToolbar: FC<SessionConfigurationToolbarProps> = ({
    sessionId,
    notifyStopSuccess,
}) => {
    const { currentSoftware } = useSessionDataHook(sessionId)
    const { sessions: sessionsAPI } = useNetworkManager()

    const [isConfirmStopSessionVisible, setIsConfirmStopSessionVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [isSessionConfigurationToolbarOpen, setIsSessionConfigurationToolbarOpen] =
        useState(false)
    const [dropdownWidth, setDropdownWidth] = useState(0)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useLayoutEffect(() => {
        if (isSessionConfigurationToolbarOpen && dropdownRef.current) {
            setDropdownWidth(dropdownRef.current.scrollWidth)
        } else {
            setDropdownWidth(0)
        }
    }, [isSessionConfigurationToolbarOpen])

    const stopSessionHandler = async (confirmation: boolean) => {
        if (confirmation) {
            setLoading(true)
            await sessionsAPI.stopSession(sessionId, currentSoftware)
            setLoading(false)
            notifyStopSuccess()
        }
        setIsConfirmStopSessionVisible(false)
    }

    const toggleDropdown = () => {
        setIsSessionConfigurationToolbarOpen((prev) => !prev)
    }

    const endSessionHelper = () => {
        setIsConfirmStopSessionVisible(true)
        setIsSessionConfigurationToolbarOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node) // Ensure button clicks don't trigger close
            ) {
                setIsSessionConfigurationToolbarOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div
            className={`session-toolbar command-button-wrapper ${
                isSessionConfigurationToolbarOpen ? 'active' : ''
            }`}
        >
            <div
                className="dropdown-menu"
                ref={dropdownRef}
                style={{
                    width: isSessionConfigurationToolbarOpen ? `${dropdownWidth}px` : '0px',
                    visibility: isSessionConfigurationToolbarOpen ? 'visible' : 'hidden',
                }}
            >
                {isSessionConfigurationToolbarOpen && (
                    <button className="command-button" onClick={endSessionHelper}>
                        Stop Session
                    </button>
                )}
            </div>

            {windowWidth >= 800 && (
                <div className="parent">
                    <button
                        ref={buttonRef}
                        className="edit-button dropdown-button"
                        onClick={toggleDropdown}
                        title="Session Options"
                    >
                        <FontAwesomeIcon icon={faGear} />
                    </button>
                </div>
            )}

            {isConfirmStopSessionVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to end this session?</div>}
                    callback={stopSessionHandler}
                    loading={loading}
                />
            )}
        </div>
    )
}

export default SessionConfigurationToolbar
