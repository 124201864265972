import { useState, FC, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { capitalizeFirstLetter } from '../../utils/misc'
import { SessionCreator } from '../sessionManager'
import { useLogEvents } from '../logging/logEvents'
import LogEventForm from '../events/logEventForm'
import SessionConfigurationToolbar from '../sessionConfigurationToolbar'
import EquipmentCommand from './equipmentCommand'
import { SessionData } from '../../../data/types'

interface SnapshotManagerProps {
    activeSession: SessionData | null
    isSessionActive: boolean
    isPreviousSessionSet: boolean
    notifyStopSuccess: () => void
    notifyEventReported: (classification: string) => void
}

const SnapshotManager: FC<SnapshotManagerProps> = ({
    activeSession,
    isSessionActive,
    isPreviousSessionSet,
    notifyStopSuccess,
    notifyEventReported,
}) => {
    const [showEventForm, setShowEventForm] = useState(false)
    const [isRecordButtonsVisible, seIsRecordButtonVisible] = useState(!isPreviousSessionSet)
    const [height, setHeight] = useState<number | undefined>(undefined)
    const [isUserResizing, setIsUserResizing] = useState(false)
    const [heightEverManuallyAdjusted, setHeightEverManuallyAdjusted] = useState(false)
    const [topBarHeight, setTopBarHeight] = useState<number>(0)

    const optionButtonsRef = useRef<HTMLDivElement | null>(null)
    const toolboxRef = useRef<HTMLDivElement | null>(null)

    const toggleEventForm = () => {
        const scrollToEventForm = () => {
            // Scroll the toolboxRef into view
            if (toolboxRef.current) {
                toolboxRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }
        // Reset height each time we toggle the form
        setHeight(undefined)
        setHeightEverManuallyAdjusted(false)
        setShowEventForm((prev) => {
            // Scroll to event form on opening
            if (!prev) scrollToEventForm()
            return !prev
        })
    }

    const bugSubmitted = (classification: string) => {
        setShowEventForm(false)
        notifyEventReported(capitalizeFirstLetter(classification))
    }

    const { logData } = useLogEvents()

    const logDataAndNotify = async (
        initiationTimestamp: Date | undefined,
        data: Record<string, any>,
        classification: string
    ) => {
        if (activeSession) {
            const success = await logData(
                activeSession.id,
                initiationTimestamp,
                data,
                classification
            )
            if (success) {
                bugSubmitted(classification)
            }
            return success
        }
        return false
    }

    useEffect(() => {
        seIsRecordButtonVisible(isPreviousSessionSet)
    }, [isPreviousSessionSet])

    useEffect(() => {
        if (optionButtonsRef.current) {
            const measuredHeight = optionButtonsRef.current.getBoundingClientRect().height
            setTopBarHeight(measuredHeight)
        }
    }, [isSessionActive, showEventForm])

    useEffect(() => {
        const handleScroll = () => {
            if (toolboxRef.current) {
                const rect = toolboxRef.current.getBoundingClientRect()
                if (rect.top >= -5 && rect.top <= 10) {
                    toolboxRef.current.classList.add('attached')
                } else {
                    toolboxRef.current.classList.remove('attached')
                }
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const toggleRecordButtons = () => {
        seIsRecordButtonVisible((prev) => !prev)
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (height === undefined) return
        e.preventDefault()

        // User is now manually resizing, so we mark it
        setIsUserResizing(true)
        setHeightEverManuallyAdjusted(true)

        const startY = e.clientY
        const startHeight = height

        const onMouseMove = (moveEvent: MouseEvent) => {
            const dy = moveEvent.clientY - startY
            const newHeight = Math.min(window.innerHeight, Math.max(100, startHeight + dy))
            setHeight(newHeight)
        }

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
            setIsUserResizing(false)
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
    }

    return (
        <div className="recorder-toolbox" ref={toolboxRef}>
            {!isSessionActive && !isPreviousSessionSet && (
                <div className="toggle-session-form">
                    <button onClick={toggleRecordButtons} className="form-button">
                        {isRecordButtonsVisible
                            ? 'Hide `New Session` Form'
                            : 'Show `New Session` Form'}
                    </button>
                </div>
            )}

            {isRecordButtonsVisible && !isSessionActive && <SessionCreator />}

            <div className="standard-view recording-icons actions">
                <div className="option-container">
                    <div
                        className="option-buttons"
                        ref={optionButtonsRef} // Ref to measure the height
                    >
                        {isSessionActive && (
                            <>
                                <div className="log-actions">
                                    <div
                                        className={`icon-button event option-button ${
                                            showEventForm ? 'highlight' : ''
                                        }`}
                                        onClick={toggleEventForm}
                                    >
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faExclamationTriangle} />
                                        </div>
                                        <span style={{ paddingLeft: '10px' }}>Log Event</span>
                                    </div>
                                    {activeSession && !showEventForm && (
                                        <>
                                            <EquipmentCommand
                                                equipmentId={activeSession.machine.id}
                                                sessionId={activeSession.id}
                                            />
                                            <EquipmentCommand
                                                equipmentId={activeSession.implement.id}
                                                sessionId={activeSession.id}
                                            />
                                        </>
                                    )}
                                </div>

                                {!showEventForm && activeSession && (
                                    <div className="controls">
                                        <SessionConfigurationToolbar
                                            sessionId={activeSession.id}
                                            notifyStopSuccess={notifyStopSuccess}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {isSessionActive && showEventForm && (
                    <LogEventForm
                        onReportEvent={logDataAndNotify}
                        isScrollOverride={true}
                        height={height}
                        topBarHeight={topBarHeight} // Pass the calculated height
                        isUserResizing={isUserResizing}
                        onMeasuredHeight={(measuredHeight) => {
                            // Only update height if user never manually resized the container
                            if (!heightEverManuallyAdjusted) {
                                if (
                                    !isUserResizing &&
                                    (height === undefined || measuredHeight !== height)
                                ) {
                                    setHeight(measuredHeight)
                                }
                            }
                        }}
                        onClose={toggleEventForm}
                        isEdit={false}
                    />
                )}
            </div>
            {showEventForm && height !== undefined && (
                <div className="resize-handle" onMouseDown={handleMouseDown}></div>
            )}
        </div>
    )
}

export default SnapshotManager
