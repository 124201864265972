import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Description } from '../../data/types'
import {
    faBug,
    faExclamationTriangle,
    faMicrochip,
    faSyncAlt,
} from '@fortawesome/free-solid-svg-icons'

const renderValue = (value: any): React.ReactNode | string => {
    if (typeof value === 'object' && value !== null) {
        return (
            <div style={{ marginLeft: '20px' }}>
                {Object.entries(value).map(([nestedKey, nestedValue]) => (
                    <div style={{ maxWidth: '800px' }} key={nestedKey}>
                        <strong>{nestedKey}:</strong> {renderValue(nestedValue)}
                    </div>
                ))}
            </div>
        )
    }
    return String(value) // Convert other types to string
}

const renderDescriptionContent = (description: Description): React.ReactNode => {
    return (
        <>
            {Object.entries(description).map(([key, value]) => (
                <div key={key}>
                    <strong>{key}:</strong> {renderValue(value)}
                </div>
            ))}
        </>
    )
}

export const getIconLabel = (type: string) => {
    switch (type) {
        case 'bug':
            return (
                <div className="icon-label bug">
                    <FontAwesomeIcon icon={faBug} /> Bug
                </div>
            )
        case 'update':
            return (
                <div className="icon-label update">
                    <FontAwesomeIcon icon={faSyncAlt} /> Update
                </div>
            )
        case 'event':
            return (
                <div className="icon-label event">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Event
                </div>
            )
        case 'device':
            return (
                <div className="icon-label device">
                    <FontAwesomeIcon icon={faMicrochip} /> Device
                </div>
            )
        default:
            return (
                <div className="icon-label event">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Event
                </div>
            )
    }
}

export const renderDescription = (description: Description): React.ReactNode => {
    const type = description ? Object.keys(description)[0] : ''
    return (
        <div className={`snapshot-tile`}>
            <div className="content">
                <div className="classification">{getIconLabel(type)}</div>
                {description &&
                    Object.keys(description).length > 0 &&
                    renderDescriptionContent(description[type])}
            </div>
        </div>
    )
}
