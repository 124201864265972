import './recorder.scss'
import { useState, FC } from 'react'
import { useNetworkManager } from '../../network/networkManager'
import { EquipmentData } from '../../data/types'
import { useReporterProfile } from '../utils/useReporterProfile'
import { MachineConfiguration } from './machineConfiguration/machineConfiguration'
import { EquipmentSoftware } from './equipmentSoftware' // Import new component

interface SessionCreatorProps {}

const SessionCreator: FC<SessionCreatorProps> = () => {
    const { sessions: sessionsAPI, equipment: equipmentApi } = useNetworkManager()

    const [sessionLoading, setSessionLoading] = useState<boolean>(false)
    const [sessionTitle, setSessionTitle] = useState<string>('')

    const [machineSoftwareVersions, setMachineSoftwareVersions] = useState<Record<string, string>>(
        {}
    )
    const [implementSoftwareVersions, setImplementSoftwareVersions] = useState<
        Record<string, string>
    >({})

    const [selectedVehicle, setSelectedVehicle] = useState<EquipmentData | null>(null)
    const [selectedImplement, setSelectedImplement] = useState<EquipmentData | null>(null)

    const reporterProfile = useReporterProfile()

    const handleSession = async () => {
        if (selectedVehicle && selectedImplement) {
            const machineSoftwareVersionsArray = Object.entries(machineSoftwareVersions).map(
                ([name, version]) => ({ name, version })
            )

            const implementSoftwareVersionsArray = Object.entries(implementSoftwareVersions).map(
                ([name, version]) => ({ name, version })
            )

            setSessionLoading(true)

            await equipmentApi.editEquipment({
                type: 'Machine',
                id: selectedVehicle.id,
                name: selectedVehicle.name,
                description: selectedVehicle.description || '',
                softwareVersions: machineSoftwareVersionsArray,
            })

            await equipmentApi.editEquipment({
                type: 'Implement',
                id: selectedImplement.id,
                name: selectedImplement.name,
                description: selectedImplement.description || '',
                softwareVersions: implementSoftwareVersionsArray,
            })

            const softwareVersions = {
                machines: machineSoftwareVersionsArray,
                _implements: implementSoftwareVersionsArray,
            }

            await sessionsAPI.addSession(
                selectedVehicle.id,
                selectedImplement.id,
                sessionTitle,
                softwareVersions
            )

            setSessionLoading(false)
        } else {
            alert('No Machine and/or Vehicle selected')
        }
    }

    const handleSoftwareVersionChange = (type: string, software: string, version: string) => {
        if (type === 'machine') {
            setMachineSoftwareVersions((prevVersions) => ({
                ...prevVersions,
                [software]: version,
            }))
        } else if (type === '_implement') {
            setImplementSoftwareVersions((prevVersions) => ({
                ...prevVersions,
                [software]: version,
            }))
        }
    }

    return (
        <div className="record-button-group">
            <div className="start-session-controller">
                <MachineConfiguration
                    setMachineSoftwareVersions={setMachineSoftwareVersions}
                    setImplementSoftwareVersions={setImplementSoftwareVersions}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    selectedImplement={selectedImplement}
                    setSelectedImplement={setSelectedImplement}
                />

                {selectedVehicle && selectedImplement && (
                    <div className="session-data-wrapper">
                        <div style={{ fontWeight: '500' }}>Session Title:</div>
                        <div className="session-title">
                            <input
                                type="text"
                                value={sessionTitle}
                                onChange={(e) => setSessionTitle(e.target.value)}
                                placeholder="Enter title"
                            />
                        </div>

                        <EquipmentSoftware
                            type="machine"
                            equipmentName={selectedVehicle.name}
                            softwareVersions={machineSoftwareVersions}
                            onVersionChange={handleSoftwareVersionChange}
                            reporterProfile={reporterProfile}
                        />
                        <EquipmentSoftware
                            type="_implement"
                            equipmentName={selectedImplement.name}
                            softwareVersions={implementSoftwareVersions}
                            onVersionChange={handleSoftwareVersionChange}
                            reporterProfile={reporterProfile}
                        />
                        <div className="start-session-wrapper">
                            <button className="form-button" onClick={handleSession}>
                                {sessionLoading ? (
                                    <>
                                        <div style={{ display: 'flex', marginRight: '20px' }}>
                                            <span className="spinner"></span>
                                        </div>
                                        <div className="start-session-title">Starting...</div>
                                    </>
                                ) : (
                                    <div className="start-session-title">Start Session</div>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export { SessionCreator }
