import { useState } from 'react'
import { EquipmentData, SoftwareVersions } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faEdit, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { RenderImages } from '../utils/images/imageRenderer'
import { DeviceSelection } from './deviceSelection'
import { TextWrapper } from '../utils/misc'

interface EquipmentOverviewProps {
    equipment: EquipmentData
    typeTitle: 'Machine' | 'Implement'
    typeIcon: IconDefinition
    onDelete?: (equipment_id: string) => Promise<void>
    onEdit?: (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => Promise<void>
    manualSoftware?: {
        initialVersions: SoftwareVersions[] | undefined
        currentFinalVersions: SoftwareVersions[] | undefined
    }
    enableDeviceModification: boolean
}

function EquipmentOverview({
    equipment,
    typeTitle,
    typeIcon,
    onDelete,
    onEdit,
    manualSoftware,
    enableDeviceModification,
}: EquipmentOverviewProps) {
    const [editingEquipment, setEditingEquipment] = useState<string | null>(null)
    const [equipmentName, setEquipmentName] = useState<string>('')
    const [equipmentDescription, setEquipmentDescription] = useState<string>('')
    const [equipmentSoftware, setEquipmentSoftware] = useState<SoftwareVersions[]>([])

    const startEditing = (equipment: EquipmentData) => {
        setEditingEquipment(equipment.id)
        setEquipmentName(equipment.name)
        setEquipmentDescription(equipment.description || '')
        setEquipmentSoftware(equipment.softwareVersions)
    }

    const handleEditEquipment = async (id: string) => {
        if (onEdit) await onEdit(id, equipmentName, equipmentDescription || '', equipmentSoftware)
        setEditingEquipment(null)
    }

    const cancelEditingEquipment = () => {
        setEditingEquipment(null)
    }

    const handleSoftwareVersionChange = (index: number, value: string) => {
        setEquipmentSoftware((prevSoftware) =>
            prevSoftware.map((software, i) =>
                i === index ? { ...software, version: value } : software
            )
        )
    }

    const renderManualSoftware = () => {
        // Combine initialVersions and currentFinalVersions, mapping missing initial versions to 'N/A'
        const combinedVersions =
            manualSoftware?.currentFinalVersions?.map((currentSoftware: any) => {
                const initialSoftware = manualSoftware?.initialVersions?.find(
                    (software: any) => software.name === currentSoftware.name
                )
                return initialSoftware || { name: currentSoftware.name, version: 'N/A' }
            }) ||
            manualSoftware?.initialVersions ||
            []

        return (
            <div className="recorder-software-versions">
                <div className="group">
                    <div className="versions">
                        <span style={{ fontWeight: '600' }}>Software Versions</span>
                        {combinedVersions.length > 0 ? (
                            combinedVersions.map((initialSoftware: any, index: number) => {
                                const matchingSoftware = manualSoftware?.currentFinalVersions?.find(
                                    (software: any) => software.name === initialSoftware.name
                                )
                                const softwareVersion = matchingSoftware
                                    ? matchingSoftware.version
                                    : initialSoftware.version
                                const hasChanged =
                                    matchingSoftware &&
                                    matchingSoftware.version !== initialSoftware.version
                                return (
                                    <div key={index}>
                                        <div style={{ fontWeight: '400', marginRight: '5px' }}>
                                            <TextWrapper
                                                text={initialSoftware.name}
                                                charLimit={40}
                                            />
                                        </div>
                                        <div style={{ fontWeight: '200', textAlign: 'center' }}>
                                            {initialSoftware.version}
                                            {hasChanged && (
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faArrowRight}
                                                        style={{ margin: '0 10px' }}
                                                    />
                                                    {softwareVersion}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div>
                                <TextWrapper text={'N/A'} charLimit={40} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    const RenderSoftware = (showSoftware: boolean, equipment: EquipmentData) => {
        return editingEquipment === equipment.id ? (
            <div className="software-version-editor">
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div className="input-group">
                    {equipmentSoftware.map((version, index) => {
                        return (
                            <div key={index}>
                                <div>{version.name}: </div>
                                <input
                                    value={version.version}
                                    onChange={(e) =>
                                        handleSoftwareVersionChange(index, e.target.value)
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        ) : showSoftware ? (
            <div>
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div>
                    {equipment.softwareVersions.length > 0
                        ? equipment.softwareVersions.map((version, index) => {
                              return (
                                  <div key={index}>
                                      {version.name}: {version.version}
                                  </div>
                              )
                          })
                        : 'N/A'}
                </div>
            </div>
        ) : null
    }

    return (
        <div className="equipment-overview">
            <div key={equipment.id} className="equipment-tile">
                {/* Add the FontAwesome image here */}
                <div className="tile-header">
                    <div className="title">
                        <RenderImages
                            images={undefined}
                            deleteImage={() => {}}
                            imageUploading={false}
                            placeholderIcon={typeIcon}
                        />
                        {editingEquipment === equipment.id ? (
                            <input
                                type="text"
                                value={equipmentName}
                                onChange={(e) => setEquipmentName(e.target.value)}
                            />
                        ) : (
                            <div>{equipment.name}</div>
                        )}
                    </div>
                    <div className="controls">
                        {editingEquipment === equipment.id ? (
                            <>
                                <button
                                    className="edit-button"
                                    title={`Save ${typeTitle}`}
                                    onClick={() => handleEditEquipment(equipment.id)}
                                >
                                    Save
                                </button>
                                <button
                                    className="edit-button"
                                    title={`Cancel ${typeTitle}`}
                                    onClick={() => cancelEditingEquipment()}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <>
                                {onEdit && (
                                    <button
                                        className="edit-button"
                                        title={`Edit ${typeTitle}`}
                                        onClick={() => startEditing(equipment)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                )}

                                {onDelete && (
                                    <button
                                        className="delete-button"
                                        title={`Delete ${typeTitle}`}
                                        onClick={() => onDelete(equipment.id)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="equipment-body">
                    <div className="equipment-metadata">
                        <div>
                            <div style={{ fontWeight: '600' }}>Description</div>
                            {editingEquipment === equipment.id ? (
                                <div className="text-area-wrapper">
                                    <textarea
                                        value={equipmentDescription}
                                        onChange={(e) => setEquipmentDescription(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <span
                                    style={{
                                        fontWeight: '300',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {equipment.description ? equipment.description : 'N/A'}
                                </span>
                            )}
                        </div>
                        {manualSoftware ? renderManualSoftware() : RenderSoftware(true, equipment)}
                    </div>
                </div>

                <DeviceSelection
                    type={typeTitle}
                    equipment={equipment}
                    enableModification={enableDeviceModification}
                />
            </div>
        </div>
    )
}

export { EquipmentOverview }
