import React, { ReactNode, useEffect, useState } from 'react'
import { EquipmentData, SessionData } from '../../data/types'
import { FormatDate, TextWrapper } from '../utils/misc'
import { useSessionDataHook } from '../helpers/sessionDataHelpers'
import { faTractor, faTrailer } from '@fortawesome/free-solid-svg-icons'
import { useImplements, useMachines } from '../../data/reducers/equipment'
import { EquipmentTile } from '../equipment/equipmentTiles'
import { EquipmentOverview } from '../equipment/equipmentOverview'

interface SessionMetadataProps {
    session: SessionData
    isAttributions?: boolean
    isUtc: boolean
    header?: ReactNode
    disableMargin?: boolean
}

const SessionMetadata: React.FC<SessionMetadataProps> = ({
    session,
    isAttributions = true,
    isUtc,
    header,
    disableMargin,
}) => {
    const { currentSoftware } = useSessionDataHook(session.id)

    const machineListData = useMachines()
    const implementsListData = useImplements()

    const [machine, setMachine] = useState<EquipmentData | null>(null)
    const [implement, setImplement] = useState<EquipmentData | null>(null)

    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('')
    const [selectedEquipment, setSelectedEquipment] = useState<EquipmentData | null>(null)

    useEffect(() => {
        const foundMachine = machineListData.find((machine) => machine.id === session.machine?.id)
        setMachine(foundMachine || null)
    }, [session, machineListData])

    useEffect(() => {
        const foundImplement = implementsListData.find(
            (implement) => implement.id === session.implement?.id
        )
        setImplement(foundImplement || null)
    }, [session, implementsListData])

    useEffect(() => {
        if (selectedEquipmentId) {
            const foundImplement = implementsListData.find(
                (implement) => implement.id === selectedEquipmentId
            )
            const foundMachine = machineListData.find(
                (machine) => machine.id === selectedEquipmentId
            )
            setSelectedEquipment(foundImplement || foundMachine || null)
        } else {
            setSelectedEquipment(null)
        }
    }, [implementsListData, machineListData, selectedEquipmentId])

    const handleEquipmentSelection = (equipment: EquipmentData) => {
        if (equipment.id === selectedEquipmentId) {
            setSelectedEquipmentId('')
        } else {
            setSelectedEquipmentId(equipment.id)
        }
    }

    return (
        <div className={`metadata ${disableMargin && 'override-margin'}`}>
            <div className="primary">
                {header}
                {isAttributions && (
                    <>
                        <div>
                            <span style={{ fontWeight: '500' }}>Started by:</span>
                            <TextWrapper
                                text={`${session.owner.given_name} ${session.owner.family_name}`}
                                charLimit={40}
                            />
                        </div>
                        <div>
                            <span style={{ fontWeight: '500' }}>Start Time</span>
                            <span>{FormatDate(session.startTime, isUtc)}</span>

                            {!session.active && (
                                <>
                                    <span style={{ fontWeight: '500' }}>Stop Time</span>
                                    <span>{FormatDate(session.stopTime, isUtc)}</span>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className="recorder-software-versions">
                <div className="group">
                    <div className={`equipment-tiles no-padding`}>
                        {machine && (
                            <EquipmentTile
                                equip={machine}
                                handleSelect={handleEquipmentSelection}
                                selectedEquipmentId={
                                    selectedEquipment?.type === 'Machine' ? machine.id : undefined
                                }
                            />
                        )}
                        {implement && (
                            <EquipmentTile
                                equip={implement}
                                handleSelect={handleEquipmentSelection}
                                selectedEquipmentId={
                                    selectedEquipment?.type === 'Implement'
                                        ? implement.id
                                        : undefined
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            {selectedEquipment && (
                <EquipmentOverview
                    equipment={selectedEquipment}
                    typeTitle={selectedEquipment.type}
                    typeIcon={selectedEquipment.type === 'Machine' ? faTractor : faTrailer}
                    manualSoftware={{
                        initialVersions:
                            selectedEquipment.type === 'Machine'
                                ? session.softwareVersions?.machines
                                : session.softwareVersions?._implements,
                        currentFinalVersions:
                            selectedEquipment.type === 'Machine'
                                ? session.active
                                    ? currentSoftware?.machines
                                    : session.finalSoftwareVersions?.machines
                                : session.active
                                ? currentSoftware?._implements
                                : session.finalSoftwareVersions?._implements,
                    }}
                    enableDeviceModification={false}
                />
            )}
        </div>
    )
}

export default SessionMetadata
