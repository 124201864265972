import './index.css'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useNetworkManager } from '../network/networkManager'
import { useCallback, useEffect, useRef, useState } from 'react'

import { RootState } from '../data/store'
import { useSelector } from 'react-redux'

import Header from '../components/header/header'
import Sidepanel from '../components/sidepanel/sidepanel'

import { useAuth0 } from '@auth0/auth0-react'

import { useSocketManager } from '../network/sockets/socketManager'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { useCurrentUser, useGlobalUserId } from '../components/utils/useGlobalUserId'
import { LoadingScreen } from '../components/loading/loadingScreen'

export const MainWrapper = () => {
    const {
        user: userAPI,
        equipment: equipmentApi,
        sessions: sessionsApi,
        groups: groupsAPI,
        devices: devicesApi,
    } = useNetworkManager()

    // Refs to track fetching
    const hasFetchedUserData = useRef(false)
    const hasFetchedUsersManagement = useRef(false)

    const hasFetchedMachinesAndJobs = useRef(false)

    const { user: auth0User, getAccessTokenSilently } = useAuth0()
    const location = useLocation()
    const navigate = useNavigate()

    const userDataStatus = useSelector((state: RootState) => state.user.loading)

    // Grabs Redux user (after store is populated) & the globalUserId
    const { currentUser, isAdmin } = useCurrentUser()
    const globalUserId = useGlobalUserId()

    const [groupId, setGroupId] = useState('')

    const [loading, setLoading] = useState(true)
    const [showLoadingScreen, setShowLoadingScreen] = useState(true) // New state for delayed hiding

    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => {
                setShowLoadingScreen(false) // Hide after 1s
            }, 1000) // 2 seconds delay

            return () => clearTimeout(timer) // Cleanup on unmount
        } else {
            setShowLoadingScreen(true) // Show immediately when loading starts
        }
    }, [loading])

    const toastShown = useRef(false)

    const { connect } = useSocketManager()

    // ----------------------------------------------------
    // (1) Socket connection effect
    // ----------------------------------------------------
    useEffect(() => {
        const initialize = async () => {
            const token = await getAccessTokenSilently()
            connect(groupId, token)
        }
        initialize()
        // eslint-disable-next-line
    }, [groupId])

    // ----------------------------------------------------
    // (2) Fetch single-user data (once)
    // ----------------------------------------------------
    useEffect(() => {
        if (!hasFetchedUserData.current && userAPI && equipmentApi && auth0User && globalUserId) {
            hasFetchedUserData.current = true
            userAPI.getUser().catch((err) => {
                console.error('Error fetching user:', err)
                hasFetchedUserData.current = false // If it fails, you could reset this.
            })
        }
    }, [userAPI, equipmentApi, auth0User, globalUserId])

    // ----------------------------------------------------
    // (3) If user is admin, fetch users management (once)
    // ----------------------------------------------------
    useEffect(() => {
        if (hasFetchedUserData.current && isAdmin && !hasFetchedUsersManagement.current) {
            hasFetchedUsersManagement.current = true
            userAPI.getUsers_management().catch((err) => {
                console.error('Error fetching users management:', err)
                hasFetchedUsersManagement.current = false // reset if needed
            })
        }
    }, [isAdmin, userAPI])

    // ----------------------------------------------------
    // (4) Callback to load group-based data
    // ----------------------------------------------------
    const updateGroupData = useCallback(async () => {
        setLoading(true)
        hasFetchedMachinesAndJobs.current = true

        try {
            await Promise.all([
                equipmentApi.fetchEquipment(),
                sessionsApi.fetchSessions(),
                groupsAPI.getGroupProfile(),
                isAdmin ? devicesApi.getAllDevices() : devicesApi.getDevices(),
                isAdmin ? groupsAPI.getGroups() : Promise.resolve(), // no-op
            ])
        } catch (error) {
            console.error('Error fetching group data:', error)
        } finally {
            setLoading(false)
        }
    }, [equipmentApi, sessionsApi, groupsAPI, devicesApi, isAdmin])

    // ----------------------------------------------------
    // (5) Pull group based data
    // ----------------------------------------------------
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const groupIdFromUrl = params.get('groupId')
        const loginParamsPresent = params.get('code') || params.get('state')
        const simpleViewFromURL = params.get('simpleView')

        const savedSearchParams = localStorage.getItem('searchParams')
        const savedPath = localStorage.getItem('savedPath')
        const savedGroupId = localStorage.getItem('groupId')
        const savedSimpleView = localStorage.getItem('simpleView')

        const hasEncodedEntities = location.search.includes('&amp;')

        const decodeAndRedirectParams = () => {
            const decodedParams = location.search.replace(/&amp;/g, '&')
            if (hasEncodedEntities) {
                navigate({ search: decodedParams }, { replace: true })
            } else {
                return true
            }
        }

        const clearLoginParams = () => {
            params.delete('code')
            params.delete('state')
            navigate({ search: params.toString() }, { replace: true })
        }

        const restoreSavedSearch = (savedPath: string, savedSearchParams: string) => {
            localStorage.removeItem('savedPath')
            localStorage.removeItem('searchParams')
            navigate(
                {
                    pathname: savedPath,
                    search: savedSearchParams,
                },
                { replace: true }
            )
        }

        const handleGroupIdUrl = (UrlId: string) => {
            const isValidGroupId =
                currentUser && currentUser.groups.some((group) => group.id === UrlId)

            if (!isValidGroupId && !toastShown.current) {
                toastShown.current = true
                toast.error(`You don't belong to this group. Please request access.`)
                navigate('/', { replace: true })
            } else if (isValidGroupId && UrlId !== groupId) {
                localStorage.setItem('groupId', UrlId)
                setGroupId(UrlId)
                hasFetchedMachinesAndJobs.current = false
            }
        }

        const handleEnabledSimpleViewUrl = (enabled: string) => {
            if (enabled === 'true') {
                localStorage.setItem('simpleView', enabled)
            } else {
                localStorage.removeItem('simpleView')
                params.delete('simpleView')
                navigate({ search: params.toString() }, { replace: true })
            }
        }

        const restoreIdFromStorage = (groupId: string) => {
            const params = new URLSearchParams(location.search)
            params.set('groupId', groupId)
            navigate({ search: params.toString() }, { replace: true })
        }

        const setDefaultId = () => {
            if (currentUser) {
                const params = new URLSearchParams(location.search)
                params.set('groupId', currentUser.groups[0].id)
                navigate({ search: params.toString() }, { replace: true })
            }
        }

        const restoreSimpleViewFromStorage = (simpleView: string) => {
            const params = new URLSearchParams(location.search)
            params.set('simpleView', simpleView)
            navigate({ search: params.toString() }, { replace: true })
        }

        if (hasEncodedEntities) {
            decodeAndRedirectParams()
        } else if (loginParamsPresent) {
            clearLoginParams()
        } else if (savedSearchParams && savedPath) {
            restoreSavedSearch(savedPath.toString(), savedSearchParams.toString())
        } else if (currentUser && currentUser.groups && currentUser.groups.length > 0) {
            if (groupIdFromUrl) {
                handleGroupIdUrl(groupIdFromUrl)
            } else {
                if (savedGroupId) {
                    const groupFound = currentUser.groups.find((o) => o.id === savedGroupId)
                    if (groupFound) {
                        restoreIdFromStorage(savedGroupId.toString())
                    } else {
                        restoreIdFromStorage(currentUser.groups[0].id)
                    }
                } else {
                    setDefaultId()
                }
            }

            if (simpleViewFromURL) {
                handleEnabledSimpleViewUrl(simpleViewFromURL.toString())
            } else if (savedSimpleView && savedSimpleView === 'true') {
                restoreSimpleViewFromStorage(savedSimpleView.toString())
            }
        }
    }, [location.search, currentUser, userDataStatus, groupId, navigate])

    // ----------------------------------------------------
    // (6) Once groupId captured, fetch group data if needed
    // ----------------------------------------------------
    useEffect(() => {
        if (
            groupId &&
            !hasFetchedMachinesAndJobs.current &&
            currentUser &&
            currentUser.id &&
            !userDataStatus
        ) {
            updateGroupData()
        }
    }, [currentUser, userDataStatus, updateGroupData, groupId])

    // ----------------------------------------------------
    // Render
    // ----------------------------------------------------
    return (
        <div id="base">
            <Header />
            <div id="main-container">
                <>
                    <ToastContainer />
                    <Sidepanel location={location.pathname} />
                    <div id="main-container-content">
                        {showLoadingScreen ? <LoadingScreen /> : <Outlet />}
                    </div>
                </>
            </div>
        </div>
    )
}

export default MainWrapper
