import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { DeviceData, GroupsData } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faSpinner, faTerminal } from '@fortawesome/free-solid-svg-icons'

import './devices.scss'
import { useNetworkManager } from '../../network/networkManager'
import { useCurrentUser } from '../utils/useGlobalUserId'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'
import { RenderFiles } from './renderFiles'
import { RenderImages } from '../utils/images/imageRenderer'

interface DeviceOverviewProps {
    device: DeviceData
    onDelete: (device_id: string) => Promise<void>
    onEdit: (id: string, name: string, description: string) => Promise<void>
    onDeleteDeviceFile: (deviceId: string, fileId: string) => void
    onDeleteDeviceImage: (deviceId: string, fileId: string) => void
    onDeviceFileDownload: (deviceId: string, fileId: string) => Promise<void>
}

function DeviceOverview({
    device,
    onDelete,
    onEdit,
    onDeleteDeviceFile,
    onDeleteDeviceImage,
    onDeviceFileDownload,
}: DeviceOverviewProps) {
    const { devices: devicesApi } = useNetworkManager()
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [deviceName, setDeviceName] = useState<string>(device.name || '')
    const [deviceDescription, setDeviceDescription] = useState<string>(device.description || '')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [dropdownWidth, setDropdownWidth] = useState<string>()

    const [groups, setGroups] = useState<GroupsData[]>([])
    const [selectedGroupsForDevice, setSelectedGroupsForDevice] = useState<GroupsData | undefined>()

    const { isAdmin } = useCurrentUser()
    const dropdownRef = useRef<HTMLDivElement>(null)
    const commandButtonWrapperRef = useRef<HTMLDivElement>(null)

    const groupsData = useSelector((state: RootState) => state.groups.data)
    useEffect(() => {
        setGroups(groupsData || [])
    }, [groupsData])
    const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name))

    useEffect(() => {
        setDeviceName(device.name || '')
        setDeviceDescription(device.description || '')
        setSelectedGroupsForDevice(
            device?.groups && device.groups.length > 0 ? device.groups[0] : undefined
        )
    }, [device])

    const commandHelper = (capability: string) => {
        const payload = { message: 'Test payload' }
        setIsDropdownOpen(false)
        devicesApi.commandDevice(device.id, capability, payload)
    }

    const updateGroups = () => {
        if (selectedGroupsForDevice)
            devicesApi.editDeviceGroups(device.id, [selectedGroupsForDevice.id])
    }

    const handleSave = async () => {
        await onEdit(device.id, deviceName, deviceDescription || '')
        setIsEditing(false)
    }

    useLayoutEffect(() => {
        if (isDropdownOpen && dropdownRef.current) {
            if (window.innerWidth <= 800) {
                setDropdownWidth('100%')
            } else {
                setDropdownWidth(`${dropdownRef.current.scrollWidth}px`)
            }
        } else {
            setDropdownWidth('0px')
        }
    }, [isDropdownOpen])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                commandButtonWrapperRef.current &&
                !commandButtonWrapperRef.current.contains(event.target as Node)
            ) {
                setIsDropdownOpen(false)
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => document.removeEventListener('click', handleClickOutside)
    }, [])

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    const deleteImageHelper = async (imageId: string) => {
        try {
            if (onDeleteDeviceImage) await onDeleteDeviceImage(device.id, imageId)
        } catch (error) {
            console.error('Error deleting image:', error)
        }
    }

    return (
        <div key={device.id} className="device-overview">
            <div className="tile-header file-header">
                <div className="title">
                    {isEditing ? (
                        <input
                            type="text"
                            value={deviceName}
                            onChange={(e) => setDeviceName(e.target.value)}
                        />
                    ) : (
                        device.name || 'Unknown Device name'
                    )}
                </div>
                <div className="controls">
                    {isEditing ? (
                        <>
                            <button
                                className="edit-button"
                                title="Save Device"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                            <button
                                className="edit-button"
                                title="Cancel Edit"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="edit-button"
                                title="Edit Device"
                                onClick={() => setIsEditing(true)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            {device.isConnected && (
                                <div
                                    className={`command-button-wrapper ${
                                        isDropdownOpen ? `active` : ''
                                    }`}
                                    ref={commandButtonWrapperRef}
                                    title="Command Device"
                                >
                                    <div className="parent">
                                        <button
                                            className="edit-button dropdown-button"
                                            onClick={toggleDropdown}
                                            disabled={device.isExecutingCommand}
                                        >
                                            {device.isExecutingCommand ? (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            ) : (
                                                <FontAwesomeIcon icon={faTerminal} />
                                            )}
                                        </button>
                                    </div>
                                    <div
                                        className="dropdown-menu"
                                        ref={dropdownRef}
                                        style={{
                                            width: isDropdownOpen ? `${dropdownWidth}` : '0px',
                                            visibility: isDropdownOpen ? 'visible' : 'hidden',
                                        }}
                                    >
                                        {isDropdownOpen &&
                                            (device.capabilities &&
                                            device.capabilities.length > 0 ? (
                                                device.capabilities
                                                    .filter((capability) =>
                                                        capability.visibility?.includes('device')
                                                    )
                                                    .map((capability) => (
                                                        <button
                                                            key={capability.callhook}
                                                            className="command-button"
                                                            onClick={() =>
                                                                commandHelper(capability.callhook)
                                                            }
                                                        >
                                                            {capability.name}
                                                        </button>
                                                    ))
                                            ) : (
                                                <div style={{ padding: '10px' }}>
                                                    No capabilities available for this device.
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}

                            {isAdmin && (
                                <button
                                    className="delete-button"
                                    title="Delete Device"
                                    onClick={() => onDelete(device.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="body">
                <div className="description">
                    <div>
                        <div style={{ fontWeight: '600' }}>Description</div>
                        {isEditing ? (
                            <div className="text-area-wrapper">
                                <textarea
                                    value={deviceDescription}
                                    onChange={(e) => setDeviceDescription(e.target.value)}
                                />
                            </div>
                        ) : (
                            <span style={{ fontWeight: '300', whiteSpace: 'pre-wrap' }}>
                                {device.description || 'No Description Provided'}
                            </span>
                        )}
                    </div>
                    <div>
                        <div style={{ fontWeight: '600' }}>Device ID</div>
                        <span style={{ fontWeight: '300' }}>{device.deviceId}</span>
                    </div>
                    <div>
                        <div style={{ fontWeight: '600' }}>Connected At</div>
                        <span style={{ fontWeight: '300' }}>
                            {device.connectedAt
                                ? new Date(device.connectedAt).toLocaleString()
                                : 'Not Connected'}
                        </span>
                    </div>

                    {isAdmin && (
                        <>
                            {isEditing ? (
                                <div className="add-group-to-user">
                                    <div className="file-label">Groups</div>
                                    <select
                                        value={selectedGroupsForDevice?.id ?? ''}
                                        onChange={(e) => {
                                            const selectedId = e.target.value
                                            const found =
                                                sortedGroups.find((g) => g.id === selectedId) ||
                                                undefined
                                            setSelectedGroupsForDevice(found)
                                        }}
                                    >
                                        <option value="">Select Group</option>
                                        {sortedGroups.map((group) => (
                                            <option key={group.id} value={group.id}>
                                                {group.name} {/* Display name, but select by ID */}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={() => {
                                            updateGroups()
                                        }}
                                    >
                                        Save Group
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div className="file-label">Groups</div>
                                    {device.groups && device.groups.length > 0 ? (
                                        device.groups.map((group) => group.name).join(', ')
                                    ) : (
                                        <span className="no-files-message">No Assigned Group</span>
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {!device.isConnected && (
                        <div>
                            <div style={{ fontWeight: '600' }}>Disconnected At</div>
                            <span style={{ fontWeight: '300' }}>
                                {device.disconnectedAt
                                    ? new Date(device.disconnectedAt).toLocaleString()
                                    : 'Still Connected'}
                            </span>
                        </div>
                    )}

                    {/* Section to list device files */}
                    <div>
                        <div className="file-label">Files</div>
                        <RenderFiles
                            files={device.files}
                            onDeleteFile={(fileId: string) => onDeleteDeviceFile(device.id, fileId)}
                            onFileDownload={(fileId: string) =>
                                onDeviceFileDownload(device.id, fileId)
                            }
                        />
                    </div>

                    <div>
                        <div className="file-label">Images</div>
                        {device.images && device.images?.length > 0 ? (
                            <RenderImages
                                images={device.images}
                                deleteImage={deleteImageHelper}
                                imageUploading={false}
                            />
                        ) : (
                            <span className="no-files-message">No Images Available</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DeviceOverview }
