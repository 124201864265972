import { useSelector } from 'react-redux'
import { EquipmentData, SessionData, SoftwareVersions } from '../../data/types'
import { useNetworkManager } from '../../network/networkManager'
import { RootState } from '../../data/store'
import { useEffect, useState } from 'react'
import { useImplements, useMachines } from '../../data/reducers/equipment'

export const useSoftwareUpdater = () => {
    const { equipment: equipmentApi } = useNetworkManager()
    const sessionData = useSelector((state: RootState) => state.sessions.data)
    const machineData = useMachines()
    const implementData = useImplements()

    const [machines, setMachines] = useState<EquipmentData[]>([])
    const [_implements, setImplements] = useState<EquipmentData[]>([])
    const [sessions, setSessions] = useState<SessionData[]>([])

    useEffect(() => {
        setMachines(machineData)
    }, [machineData])

    useEffect(() => {
        setImplements(implementData)
    }, [implementData])

    useEffect(() => {
        setSessions(sessionData)
    }, [sessionData])

    const updateSoftwareVersion = async (
        sessionId: string,
        version: SoftwareVersions,
        softwareType: 'machine' | 'implement'
    ) => {
        const session = sessions.find((session) => {
            return session.id === sessionId
        })

        const machine = machines.find((machine) => {
            return machine.id === session?.machine.id
        })

        const _implement = _implements.find((implement) => {
            return implement.id === session?.implement.id
        })

        if (softwareType === 'machine') {
            if (machine) {
                let softwareVersions = machine.softwareVersions.map((sv) => {
                    if (sv.name === version.name) {
                        return { ...sv, version: version.version }
                    }
                    return sv
                })
                // If version.name is not found, add the new version
                if (!softwareVersions.some((sv) => sv.name === version.name)) {
                    softwareVersions = [...softwareVersions, version]
                }
                await equipmentApi.editEquipment({
                    type: 'Machine',
                    id: machine.id,
                    name: machine.name,
                    description: machine.description || '',
                    softwareVersions,
                })
            }
        } else {
            if (_implement) {
                let softwareVersions = _implement.softwareVersions.map((sv) => {
                    if (sv.name === version.name) {
                        return { ...sv, version: version.version }
                    }
                    return sv
                })
                // If version.name is not found, add the new version
                if (!softwareVersions.some((sv) => sv.name === version.name)) {
                    softwareVersions = [...softwareVersions, version]
                }
                await equipmentApi.editEquipment({
                    type: 'Implement',
                    id: _implement.id,
                    name: _implement.name,
                    description: _implement.description || '',
                    softwareVersions,
                })
            }
        }
    }

    return { updateSoftwareVersion }
}
