import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { DeviceData } from '../data/types'
import { useNetworkManager } from '../network/networkManager'
import { DeviceOverview } from '../components/devices/deviceOverview'
import { ConfirmationPopup } from '../components/misc/popup'
import { useSearchParams } from 'react-router-dom'
import { DeviceTileList } from '../components/devices/deviceTile'

type ConfirmationAction = 'deleteFile' | 'deleteDevice' | 'deleteImage' | null

type ConfirmationData = {
    deviceId: string
    fileId?: string
}

function Devices() {
    const [devices, setDevices] = useState<DeviceData[]>([])
    const deviceData = useSelector((state: RootState) => state.devices.data)

    const { devices: devicesApi } = useNetworkManager()

    // Unified state for confirmation dialog
    const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(false)
    const [confirmationAction, setConfirmationAction] = useState<ConfirmationAction>(null)
    const [confirmationData, setConfirmationData] = useState<ConfirmationData | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setDevices(deviceData)
    }, [deviceData])

    const [searchParams, setSearchParams] = useSearchParams()
    const selectedDevice = searchParams.get('deviceSelected')

    const handleEditDevice = async (id: string, name: string, description: string) => {
        await devicesApi.updateDevice(id, name, description)
    }

    const handleDeleteDeviceFile = (deviceId: string, fileId: string) => {
        setConfirmationAction('deleteFile')
        setConfirmationData({ deviceId, fileId })
        setIsConfirmationVisible(true)
    }

    const handleDeviceFileDownload = async (deviceId: string, fileId: string) => {
        await devicesApi.downloadDeviceFile(deviceId, fileId)
    }

    const handleDeleteDevice = async (deviceId: string) => {
        setConfirmationAction('deleteDevice')
        setConfirmationData({ deviceId })
        setIsConfirmationVisible(true)
    }

    const deleteImageHandler = (deviceId: string, fileId: string) => {
        setConfirmationAction('deleteImage')
        setConfirmationData({ deviceId, fileId })
        setIsConfirmationVisible(true)
    }

    const handleConfirmation = async (confirmation: boolean) => {
        if (confirmation && confirmationData) {
            setLoading(true)
            if (confirmationAction === 'deleteFile' && confirmationData.fileId) {
                await devicesApi.deleteDeviceFile(
                    confirmationData.deviceId,
                    confirmationData.fileId
                )
            } else if (confirmationAction === 'deleteImage' && confirmationData.fileId) {
                await devicesApi.deleteDeviceImage(
                    confirmationData.deviceId,
                    confirmationData.fileId
                )
            } else if (confirmationAction === 'deleteDevice') {
                await devicesApi.deleteDevice(confirmationData.deviceId)
            }
            setLoading(false)
        }
        // Reset state after action
        setIsConfirmationVisible(false)
        setConfirmationAction(null)
        setConfirmationData(null)
    }

    const handleDeviceSelect = (deviceId: string) => {
        setSearchParams((prevParams) => {
            // Create a copy so we don’t mutate prevParams directly
            const newParams = new URLSearchParams(prevParams)

            if (deviceId === newParams.get('deviceSelected')) {
                newParams.delete('deviceSelected')
            } else {
                newParams.set('deviceSelected', deviceId)
            }

            return newParams
        })
    }

    const selectedEquipment = devices.find((device) => device.id === selectedDevice) || null

    return (
        <div className="device-container-wrapper">
            <div className="device-container-flex">
                <div className="device-container">
                    {isConfirmationVisible && (
                        <ConfirmationPopup
                            ChildComponent={
                                <div>
                                    {confirmationAction === 'deleteFile'
                                        ? 'Are you sure you want to delete this file?'
                                        : 'Are you sure you want to delete this device?'}
                                </div>
                            }
                            callback={handleConfirmation}
                            loading={loading}
                        />
                    )}
                    <div className="device-list">
                        <header>Devices</header>

                        <DeviceTileList
                            devices={devices}
                            selectedDeviceId={selectedDevice}
                            onSelect={handleDeviceSelect}
                        />
                    </div>
                </div>
                {selectedEquipment && (
                    <div className="device-container">
                        <div className="title">Device Overview</div>
                        <DeviceOverview
                            device={selectedEquipment}
                            onEdit={handleEditDevice}
                            onDelete={handleDeleteDevice}
                            onDeleteDeviceFile={handleDeleteDeviceFile}
                            onDeviceFileDownload={handleDeviceFileDownload}
                            onDeleteDeviceImage={deleteImageHandler}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export { Devices }
