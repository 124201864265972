import { useEffect, useRef, useState } from 'react'
import SimpleSnapshotManager from '../snapshotManagers/simpleSnapshotManager'

interface LogEventFormProps {
    onReportEvent: (
        initiationTimestamp: Date | undefined,
        data: Record<string, any>,
        classification: string
    ) => Promise<boolean | undefined>
    defaultOptions?: Record<string, any>
    onClose?: () => void
    isEdit?: boolean
    isScrollOverride?: boolean
    topBarHeight?: number
    height?: number
    onMeasuredHeight?: (height: number) => void
    isUserResizing?: boolean
}

const LogEventForm = ({
    onReportEvent,
    defaultOptions,
    onClose,
    isEdit,
    isScrollOverride = false,
    topBarHeight,
    height,
    onMeasuredHeight,
    isUserResizing,
}: LogEventFormProps) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const resizeObserverRef = useRef<ResizeObserver | null>(null)
    const [constrainedHeight, setConstrainedHeight] = useState<number | undefined>(height)
    const [isTransitioning, setIsTransitioning] = useState(false)

    useEffect(() => {
        const updateHeightConstraint = () => {
            const maxHeight = window.innerHeight - (topBarHeight || 0)
            if (window.innerHeight <= 800) {
                setConstrainedHeight(maxHeight)
            } else if (height !== undefined) {
                setConstrainedHeight(Math.min(height, maxHeight))
            }
        }

        // Initialize and update on resize
        updateHeightConstraint()
        window.addEventListener('resize', updateHeightConstraint)

        return () => {
            window.removeEventListener('resize', updateHeightConstraint)
        }
    }, [height, topBarHeight])

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current
        if (!scrollContainer) return

        let startY = 0
        let startScrollTop = 0

        function onWheel(event: WheelEvent) {
            if (!isScrollOverride) return
            event.preventDefault()
            if (scrollContainer) scrollContainer.scrollTop += event.deltaY
        }

        function onTouchStart(event: TouchEvent) {
            if (!isScrollOverride) return
            startY = event.touches[0].clientY
            if (scrollContainer) startScrollTop = scrollContainer.scrollTop
        }

        function onTouchMove(event: TouchEvent) {
            if (!isScrollOverride) return
            event.preventDefault()
            const currentY = event.touches[0].clientY
            const deltaY = startY - currentY
            if (scrollContainer) scrollContainer.scrollTop = startScrollTop + deltaY
        }

        if (isScrollOverride) {
            scrollContainer.addEventListener('wheel', onWheel, { passive: false })
            scrollContainer.addEventListener('touchstart', onTouchStart, { passive: false })
            scrollContainer.addEventListener('touchmove', onTouchMove, { passive: false })

            return () => {
                scrollContainer.removeEventListener('wheel', onWheel)
                scrollContainer.removeEventListener('touchstart', onTouchStart)
                scrollContainer.removeEventListener('touchmove', onTouchMove)
            }
        }
    }, [isScrollOverride])

    useEffect(() => {
        if (!containerRef.current || !onMeasuredHeight) return

        if (resizeObserverRef.current) {
            resizeObserverRef.current.disconnect()
        }

        resizeObserverRef.current = new ResizeObserver((entries) => {
            if (!onMeasuredHeight) return

            for (const entry of entries) {
                const newHeight = entry.contentRect.height

                // Only update if content is larger AND not currently resizing
                if (!isUserResizing && (height === undefined || newHeight !== height)) {
                    onMeasuredHeight(newHeight + 20)
                }
            }
        })

        resizeObserverRef.current.observe(containerRef.current)

        return () => {
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect()
            }
        }
    }, [height, onMeasuredHeight, isUserResizing])

    useEffect(() => {
        const element = scrollContainerRef.current
        if (!element) return

        const handleTransitionEnd = () => {
            setIsTransitioning(false)
        }

        element.addEventListener('transitionend', handleTransitionEnd)

        return () => {
            element.removeEventListener('transitionend', handleTransitionEnd)
        }
    }, [])

    const handleTransitionStart = () => {
        setIsTransitioning(true)
    }

    return (
        <div
            className={`bug-reporter-parent-wrapper ${isScrollOverride ? '' : 'override-height'} ${
                isTransitioning ? 'disable-overflow' : ''
            } ${isUserResizing ? '' : 'enable-transition'}`}
            style={constrainedHeight ? { height: constrainedHeight } : {}}
            ref={scrollContainerRef}
            onMouseDown={handleTransitionStart}
        >
            <div className="bug-reporter" ref={containerRef}>
                <SimpleSnapshotManager
                    onReportEvent={onReportEvent}
                    defaultOptions={defaultOptions}
                    isEdit={isEdit}
                    onClose={onClose}
                />
            </div>
        </div>
    )
}

export default LogEventForm
