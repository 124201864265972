import { EquipmentData } from '../../data/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { CreationForm } from './equipmentForm'
import './equipment.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

interface EquipmentTilesProps {
    typeTitle: string
    typeIcon: IconDefinition
    equipment: EquipmentData[]
    selectedEquipmentId: string | undefined
    onCreate: (name: string, description: string) => Promise<void>
    onSelect: (equipmentId: string) => void
    allowSelectionChange?: boolean
    displayColumn?: boolean
}

interface EquipmentTileProps {
    equip: EquipmentData
    isEditable?: boolean
    selectedEquipmentId?: string
    allowSelectionChange?: boolean
    handleSelect?: (equip: EquipmentData) => void
    setIsEditable?: (value: boolean) => void
}

const EquipmentTile = ({
    equip,
    isEditable = false,
    selectedEquipmentId,
    allowSelectionChange = false,
    handleSelect = () => {},
    setIsEditable = () => {},
}: EquipmentTileProps) => {
    return (
        <div
            key={equip.id}
            className={`equipment-tile ${
                selectedEquipmentId === equip.id && (isEditable || !allowSelectionChange)
                    ? 'selected'
                    : ''
            }`}
            onClick={() => handleSelect(equip)}
            style={{
                cursor: allowSelectionChange
                    ? isEditable
                        ? 'pointer'
                        : selectedEquipmentId === equip.id
                        ? 'default'
                        : 'pointer'
                    : 'pointer',
            }}
        >
            <div className="tile-header">
                <div className="title">{equip.name}</div>
                {!isEditable && allowSelectionChange && selectedEquipmentId === equip.id && (
                    <FontAwesomeIcon
                        icon={faWrench}
                        className="wrench-icon"
                        onClick={(e) => {
                            e.stopPropagation() // Prevent triggering selection
                            setIsEditable(true)
                        }}
                        title="Change selection"
                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                )}
            </div>

            <div className="equipment-body">
                <div className="equipment-metadata">
                    <div>
                        <div style={{ fontWeight: '600' }}>Description</div>
                        <span
                            style={{
                                fontWeight: '300',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {equip.description ? equip.description : 'N/A'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function EquipmentTiles({
    typeTitle,
    typeIcon,
    equipment,
    selectedEquipmentId,
    onCreate,
    onSelect,
    allowSelectionChange = false,
    displayColumn = false,
}: EquipmentTilesProps) {
    const [isEditable, setIsEditable] = useState(false)

    const handleSelect = (equip: EquipmentData) => {
        if (!allowSelectionChange || isEditable || !selectedEquipmentId) {
            onSelect(equip.id)
            setIsEditable(false) // Lock selection again after choosing
        }
    }

    useEffect(() => {
        setIsEditable(false)
    }, [selectedEquipmentId])

    return (
        <>
            <header>{typeTitle} Garage</header>
            <div className={`equipment-tiles ${displayColumn ? 'display-column' : ''}`}>
                {/* When selection change is not allowed, show the creation form */}
                {!allowSelectionChange && (
                    <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />
                )}

                {equipment
                    .filter(
                        (equip) =>
                            !allowSelectionChange || isEditable || equip.id === selectedEquipmentId
                    )
                    .map((equip) => (
                        <EquipmentTile
                            key={equip.id}
                            equip={equip}
                            isEditable={isEditable}
                            selectedEquipmentId={selectedEquipmentId}
                            allowSelectionChange={allowSelectionChange}
                            handleSelect={handleSelect}
                            setIsEditable={setIsEditable}
                        />
                    ))}

                {allowSelectionChange && (isEditable || equipment.length === 0) && (
                    <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />
                )}
            </div>
        </>
    )
}

export { EquipmentTiles, EquipmentTile }
