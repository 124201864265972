import { useState, FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import { useNetworkManager } from '../../../network/networkManager'
import { DeviceData, EquipmentData } from '../../../data/types'

import './equipmentCommand.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSpinner, faTerminal } from '@fortawesome/free-solid-svg-icons'

interface EquipmentCommandProps {
    equipmentId: string
    sessionId: string
}

const EquipmentCommand: FC<EquipmentCommandProps> = ({ equipmentId, sessionId }) => {
    const { devices: devicesApi } = useNetworkManager()
    const dropdownRef = useRef<HTMLDivElement>(null)
    const deviceRefs = useRef<{ [key: string]: HTMLDivElement | null }>({}) // Store references to device names

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState<string | null>(null) // Track clicked device
    const [commandMenuStyle, setCommandMenuStyle] = useState<{
        [key: string]: React.CSSProperties
    }>({})

    const [devices, setDevices] = useState<DeviceData[]>([])
    const deviceData = useSelector((state: RootState) => state.devices.data)

    const [equipment, setEquipment] = useState<EquipmentData | null>(null)
    const equipmentData = useSelector((state: RootState) => state.equipment.data)

    useEffect(() => {
        const foundEquipment = equipmentData.find((eq) => eq.id === equipmentId) || null
        setEquipment(foundEquipment)
    }, [equipmentData, equipmentId])

    useEffect(() => {
        if (equipment) {
            const associatedDevices =
                deviceData.filter((dev) => dev.equipment === equipment.id) || []
            setDevices(associatedDevices)
        }
    }, [deviceData, equipment])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false)
                setSelectedDevice(null) // Close command menu when clicking outside
            }
        }

        if (isDropdownOpen) {
            document.addEventListener('click', handleClickOutside)
        }

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isDropdownOpen])

    const commandHelper = (deviceId: string, capability: string) => {
        const device = devices.find((dev) => dev.id === deviceId)

        if (device) {
            const payload = { sessionId }
            setIsDropdownOpen(false)
            setSelectedDevice(null)

            devicesApi.commandDevice(device.id, capability, payload)
        }
    }

    const handleDeviceClick = (deviceId: string) => {
        setSelectedDevice((prev) => (prev === deviceId ? null : deviceId)) // Toggle menu

        // Get reference to the device name element
        const deviceElement = deviceRefs.current[deviceId]
        const parentElement = dropdownRef.current // Get the closest relatively positioned parent

        if (deviceElement && parentElement) {
            if (window.innerWidth > 800) {
                setCommandMenuStyle((prev) => ({
                    ...prev,
                    [deviceId]: {
                        position: 'absolute',
                        top: `${deviceElement.offsetTop}px`,
                        left: `${deviceElement.offsetLeft + deviceElement.offsetWidth}px`, // Align to right
                        zIndex: 1000,
                    },
                }))
            }
        }
    }

    return (
        <>
            {devices.length > 0 && (
                <div className="session-equipment-command" ref={dropdownRef}>
                    <div className="parent-button-wrapper">
                        <button
                            className="icon-button event option-button"
                            onClick={() => {
                                if (isDropdownOpen) setSelectedDevice(null)
                                setIsDropdownOpen(!isDropdownOpen)
                            }}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faTerminal} />
                            </div>
                            <span style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
                                {equipment?.name}
                            </span>
                        </button>
                    </div>
                    {isDropdownOpen && (
                        <div className="command-container">
                            {devices.some(
                                (dev) =>
                                    dev.capabilities &&
                                    Object.keys(dev.capabilities).length > 0 &&
                                    dev.isConnected
                            ) ? (
                                <div className="device-container">
                                    {devices.map((device) => (
                                        <div
                                            key={device.id}
                                            ref={(el) => {
                                                deviceRefs.current[device.id] = el
                                            }}
                                            onClick={() => handleDeviceClick(device.id)}
                                            className="icon-button event option-button"
                                        >
                                            <div className="device-name">
                                                <div>{device.name}</div>
                                                {device.isExecutingCommand ? (
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className={
                                                            device.isConnected
                                                                ? 'icon-online'
                                                                : 'icon-offline'
                                                        }
                                                    />
                                                )}
                                            </div>
                                            {selectedDevice === device.id &&
                                                device.capabilities &&
                                                device.capabilities.length > 0 &&
                                                device.isConnected &&
                                                !device.isExecutingCommand && (
                                                    <div
                                                        className="command-buttons"
                                                        style={commandMenuStyle[device.id] || {}}
                                                    >
                                                        {device.capabilities
                                                            .filter((capability) =>
                                                                capability.visibility?.includes(
                                                                    'session'
                                                                )
                                                            )
                                                            .map((capability) => (
                                                                <button
                                                                    key={`${device.id}-${capability.callhook}`}
                                                                    className="icon-button event"
                                                                    onClick={() =>
                                                                        commandHelper(
                                                                            device.id,
                                                                            capability.callhook
                                                                        )
                                                                    }
                                                                >
                                                                    {capability.name}
                                                                </button>
                                                            ))}
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="icon-button event option-button">
                                    {devices.every((dev) => !dev.isConnected)
                                        ? 'No Devices Online'
                                        : 'No Available Commands.'}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default EquipmentCommand
