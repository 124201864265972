import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeviceData } from '../../data/types'
import { useCurrentUser, useGroup } from '../utils/useGlobalUserId'
import { faLink, faUnlink, faUserGroup } from '@fortawesome/free-solid-svg-icons'

interface DeviceTileProps {
    devices: DeviceData[] // Accepts an array of devices
    selectedDeviceId: string | null // Tracks the currently selected device
    onSelect: (deviceId: string) => void
}

function DeviceTileList({ devices, selectedDeviceId, onSelect }: DeviceTileProps) {
    const { isAdmin } = useCurrentUser()

    // Sort devices: First by `isConnected` (true first), then alphabetically by `name`
    const sortedDevices = [...devices].sort((a, b) => {
        if (a.isConnected !== b.isConnected) {
            return a.isConnected ? -1 : 1 // Connected devices come first
        }
        return (a.name || '').localeCompare(b.name || '') // Then sort alphabetically
    })

    const { group: groupData } = useGroup()

    // Separate devices into two arrays if we have a groupId in the URL
    let devicesInGroup: DeviceData[] = []
    let devicesNotInGroup: DeviceData[] = []

    if (isAdmin && groupData?.id) {
        devicesInGroup = sortedDevices.filter((device) =>
            device.groups?.some((group) => group.id === groupData?.id)
        )
        devicesNotInGroup = sortedDevices.filter(
            (device) => !device.groups?.some((group) => group.id === groupData?.id)
        )
    }

    // Helper function to render one list of device tiles
    const renderDeviceTiles = (list: DeviceData[]) => {
        return list.map((device) => (
            <div key={device.id} className="device-tile" onClick={() => onSelect(device.id)}>
                <div className="device">
                    <div className="metadata">
                        <div className="input-wrapper">
                            <input
                                type="radio"
                                name="deviceSelection"
                                checked={selectedDeviceId === device.id}
                                onChange={() => onSelect(device.id)}
                                onClick={(e) => e.stopPropagation()} // Prevent triggering row click
                            />
                        </div>
                        <div className="title">{device.name || 'Unknown Device name'}</div>
                        <div className="device-id">
                            <span>{device.deviceId}</span>
                        </div>
                    </div>
                    <div className="status">
                        <div
                            className={`indicator small ${
                                device.isConnected ? 'online' : 'offline'
                            }`}
                        >
                            {device.isConnected ? (
                                <FontAwesomeIcon icon={faLink} title="Connected" />
                            ) : (
                                <FontAwesomeIcon icon={faUnlink} title="Disconnected" />
                            )}

                            {/* Show admin unasigned devices */}
                            {isAdmin &&
                                (device.groups && device.groups.length > 0 ? (
                                    <span
                                        style={{ marginLeft: '10px', color: 'green' }}
                                        title="Group Assigned"
                                    >
                                        <FontAwesomeIcon icon={faUserGroup} />
                                    </span>
                                ) : (
                                    <span
                                        style={{ marginLeft: '10px', color: 'red' }}
                                        title="No Group Assigned"
                                    >
                                        <FontAwesomeIcon icon={faUserGroup} />
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    const renderDeviceHeader = () => {
        return (
            <div className="device-header">
                <div className="metadata">
                    <div className="input-wrapper"></div>
                    <div className="title">Name</div>
                    <div>Device ID</div>
                </div>
                <div>Status</div>
            </div>
        )
    }

    return (
        <div className="device-manager-wrapper">
            {isAdmin && groupData?.id ? (
                <>
                    {/* Devices in current group */}
                    <h3>{groupData?.name}</h3>
                    {devicesInGroup.length > 0 && renderDeviceHeader()}

                    {devicesInGroup.length > 0 ? (
                        renderDeviceTiles(devicesInGroup)
                    ) : (
                        <p>No devices in this group.</p>
                    )}

                    {/* Devices not in current group */}
                    <h3>Other Devices</h3>
                    {renderDeviceHeader()}

                    {devicesNotInGroup.length > 0 ? (
                        renderDeviceTiles(devicesNotInGroup)
                    ) : (
                        <p>All devices are in this group or none are available.</p>
                    )}
                </>
            ) : (
                <>
                    {sortedDevices.length > 0 && renderDeviceHeader()}

                    {renderDeviceTiles(sortedDevices)}
                </>
            )}
        </div>
    )
}

export { DeviceTileList }
