import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { EquipmentData, SoftwareVersions } from '../data/types'
import { EquipmentTiles } from '../components/equipment/equipmentTiles'
import { useNetworkManager } from '../network/networkManager'
import { ConfirmationPopup } from '../components/misc/popup'
import { faTractor } from '@fortawesome/free-solid-svg-icons'
import { EquipmentOverview } from '../components/equipment/equipmentOverview'
import { useMachines } from '../data/reducers/equipment'

function Vehicles() {
    const [vehicles, setVehicles] = useState<EquipmentData[]>([])
    const machineData = useMachines()
    const { equipment: vehicleApi } = useNetworkManager()
    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [vehicleToDelete, setVehicleIdToDelete] = useState<string | null>(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const selectedVehicle = searchParams.get('equipmentSelected') || undefined

    useEffect(() => {
        setVehicles(machineData)
    }, [machineData])

    const handleCreateVehicle = async (name: string, description: string) => {
        await vehicleApi.addEquipment({ type: 'Machine', name, description })
    }

    const handleDeleteVehicle = async (machine_id: string) => {
        setVehicleIdToDelete(machine_id)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && vehicleToDelete) {
            setLoading(true)
            await vehicleApi.deleteEquipment({ type: 'Machine', id: vehicleToDelete })
            setLoading(false)
            setVehicleIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const handleEditVehicle = async (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => {
        vehicleApi.editEquipment({ type: 'Machine', id, name, description, softwareVersions })
    }

    const handleVehicleSelect = (machine_id: string) => {
        if (selectedVehicle === machine_id) {
            searchParams.delete('equipmentSelected')
            setSearchParams(searchParams)
        } else {
            setSearchParams({ equipmentSelected: machine_id })
        }
    }

    // Find the selected vehicle from the list
    const selectedEquipment =
        vehicles.find((vehicle) => vehicle.id === selectedVehicle) || undefined

    return (
        <div className="equipment-container-wrapper">
            <div className="equipment-container">
                {isDialogueVisible && (
                    <ConfirmationPopup
                        ChildComponent={<div>Are you sure you want to delete this vehicle?</div>}
                        callback={deleteHandler}
                        loading={loading}
                    />
                )}

                <EquipmentTiles
                    typeTitle={'Vehicle'}
                    typeIcon={faTractor}
                    equipment={vehicles}
                    selectedEquipmentId={selectedVehicle}
                    onCreate={handleCreateVehicle}
                    onSelect={handleVehicleSelect}
                />

                {selectedEquipment && (
                    <EquipmentOverview
                        equipment={selectedEquipment}
                        typeTitle={'Machine'}
                        typeIcon={faTractor}
                        onEdit={handleEditVehicle}
                        onDelete={handleDeleteVehicle}
                        enableDeviceModification={true}
                    />
                )}
            </div>
        </div>
    )
}

export default Vehicles
