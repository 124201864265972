import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, EquipmentData, ErrorPayload } from '../types'
import { sanitizeEquipmentData, sanitizeEquipment } from '../../components/utils/dataSanitizer'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useMemo } from 'react'

const initialState: DataState<EquipmentData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const dataSlice = createSlice({
    name: 'equipment',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchEquipmentSuccess(state, action: PayloadAction<EquipmentData[]>) {
            state.loading = false
            state.data = sanitizeEquipmentData(action.payload)
            state.error.message = ''
        },
        addEquipmentSuccess(state, action: PayloadAction<EquipmentData>) {
            state.loading = false
            const machineIndex = state.data.findIndex((machine) => machine.id === action.payload.id)
            if (machineIndex === -1) {
                state.data.push(action.payload) // Add new machine
            } else {
                state.data[machineIndex] = action.payload // Update existing machine
            }
            state.error.message = ''
        },
        editEquipmentSuccess(state, action: PayloadAction<EquipmentData>) {
            state.loading = false
            const machineIndex = state.data.findIndex((machine) => machine.id === action.payload.id)
            if (machineIndex !== -1) {
                state.data[machineIndex] = sanitizeEquipment(action.payload)
            }
            state.error.message = ''
        },
        deleteEquipmentSuccess(state, action: PayloadAction<EquipmentData>) {
            state.loading = false
            state.data = state.data.filter((machine) => machine.id !== action.payload.id)
            state.error.message = ''
        },
    },
})

// Machines
export function useMachines() {
    const allEquipment = useSelector((state: RootState) => state.equipment.data)

    const machinesData = useMemo(() => {
        return allEquipment.filter((item) => item.type === 'Machine')
    }, [allEquipment])
    return machinesData
}

// Implements
export function useImplements() {
    const allEquipment = useSelector((state: RootState) => state.equipment.data)

    const implementsData = useMemo(() => {
        return allEquipment.filter((item) => item.type === 'Implement')
    }, [allEquipment])
    return implementsData
}

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchEquipmentSuccess,
    addEquipmentSuccess,
    editEquipmentSuccess,
    deleteEquipmentSuccess,
} = dataSlice.actions
export default dataSlice.reducer
