import React, { useState, useEffect } from 'react'
import { SessionData } from '../../data/types'
import './sessionTiles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

interface SessionTilesProps {
    sessions: SessionData[] | []
    onSelect: (sessionId: string) => void
    onResume: (sessionId: string) => void
    onDelete: (sessionId: string) => void
    isDescendingSessionOrder: boolean
    selectedSessionId: string | undefined
}

const SessionTiles: React.FC<SessionTilesProps> = ({
    sessions,
    onSelect,
    onResume,
    onDelete,
    isDescendingSessionOrder = true,
    selectedSessionId,
}) => {
    const [menuOpen, setMenuOpen] = useState<string | null>(null)
    const isAnySessionActive = sessions.some((session) => session.active)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !(event.target as HTMLElement).closest('.context-menu') &&
                !(event.target as HTMLElement).closest('.context-menu-icon')
            ) {
                setMenuOpen(null)
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const toggleMenu = (sessionId: string, event: React.MouseEvent) => {
        event.stopPropagation()
        setMenuOpen(menuOpen === sessionId ? null : sessionId)
    }

    const handleMenuAction = (action: () => void, event: React.MouseEvent) => {
        event.stopPropagation()
        action()
        setMenuOpen(null)
    }

    const sortedSessions = isDescendingSessionOrder ? [...sessions].reverse() : sessions

    const renderDeviceHeader = () => (
        <div className="sessions-header">
            <div className="title primary">
                <span style={{ marginLeft: '30px' }}>Title</span>
            </div>
            <div className="start-time">Start Time</div>
            <div className="status">Status</div>
        </div>
    )

    const renderSessionTiles = () =>
        sortedSessions.length > 0 ? (
            sortedSessions.map((session) => (
                <div
                    key={session.id}
                    className="session-entry"
                    onClick={() => onSelect(session.id)}
                >
                    <div className="metadata">
                        <div className="primary">
                            <input
                                type="radio"
                                name="sessionSelection"
                                checked={selectedSessionId === session.id}
                                onChange={() => onSelect(session.id)}
                            />
                            <div className="title">{session.title}</div>
                        </div>
                        <div className="start-time">
                            {new Date(session.startTime).toLocaleString()}
                        </div>
                        <div className={`status ${session.active ? 'active' : 'inactive'}`}>
                            {session.active ? 'Active' : 'Inactive'}
                            <div
                                className="context-menu-icon"
                                onClick={(e) => toggleMenu(session.id, e)}
                            >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </div>
                            {menuOpen === session.id && (
                                <div className="context-menu">
                                    {isAnySessionActive ? (
                                        <div className="disabled-message">
                                            Disabled - Active Session
                                        </div>
                                    ) : (
                                        <>
                                            <button
                                                onClick={(e) =>
                                                    handleMenuAction(() => onResume(session.id), e)
                                                }
                                            >
                                                Resume
                                            </button>
                                            <button
                                                onClick={(e) =>
                                                    handleMenuAction(() => onDelete(session.id), e)
                                                }
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div>No Recorded Sessions</div>
        )

    return (
        <div className="recorded-sessions">
            {renderDeviceHeader()}
            {renderSessionTiles()}
        </div>
    )
}

export default SessionTiles
