import { GenericFile } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons'

import './devices.scss'

interface DeviceOverviewProps {
    files: GenericFile[] | undefined
    onDeleteFile?: (fileId: string) => void
    onFileDownload: (fileId: string) => Promise<void>
}

function RenderFiles({ files, onDeleteFile, onFileDownload }: DeviceOverviewProps) {
    return (
        <div>
            {files && files.length > 0 ? (
                <div className="device-files">
                    {files.map((file, index) => (
                        <div key={index} className="device-file-card">
                            <div className="device-file-header">
                                <div>{file.friendlyName}</div>
                                {!file.S3UploadProgress && (
                                    <div className="file-actions">
                                        <button
                                            className="edit-button"
                                            title="Download File"
                                            onClick={() => onFileDownload(file.id)}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                        </button>
                                        {onDeleteFile && (
                                            <button
                                                className="delete-button"
                                                title="Delete File"
                                                onClick={() => onDeleteFile(file.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="file-value">Size: {file.fileSize}</div>
                            {file.S3UploadProgress && (
                                <div className="file-value">Progress: {file.S3UploadProgress}</div>
                            )}
                            <div className="file-value">Date Created: {file.dateCreated}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <span className="no-files-message">No Files Available</span>
            )}
        </div>
    )
}

export { RenderFiles }
