import { useEffect, useState, useRef, ReactNode, useMemo } from 'react'
import { SessionData } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faCheck, faTimes, faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import { useNetworkManager } from '../../network/networkManager'
import { ConfirmationPopup } from '../misc/popup'
import { TileView } from './tileView'

import './sessionTiles.css'
import { toast } from 'react-toastify'
import SessionMetadata from './SessionMetadata'
import { useFileManager } from '../../network/fileManager'

interface SessionTileProps {
    session: SessionData | null
    onResume?: (sessionId: string) => void
    onDelete?: (sessionId: string) => void
    enableDuplicate?: boolean
    displayEventList?: boolean
    isDescending?: boolean
    isUtc?: boolean
    scrollToEvent?: string
    showSessionTools?: boolean
    customComponent?: ReactNode
}

const SessionTile = ({
    session,
    onResume,
    onDelete,
    enableDuplicate = false,
    displayEventList = false,
    isDescending = true,
    isUtc = true,
    scrollToEvent = '',
    showSessionTools = true,
    customComponent,
}: SessionTileProps) => {
    const { sessions: sessionsAPI } = useNetworkManager()
    const { imageUpload } = useFileManager()

    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(null)

    const [isCollapsed, setIsCollapsed] = useState(!displayEventList)
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false)
    const [editedTitle, setEditedTitle] = useState<string>(session?.title || '')

    const hideSnapshot = () => {
        setIsCollapsed(!isCollapsed)
    }

    const snapshotRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

    const handleDeleteSnapshot = async (snapshotId: string) => {
        setSessionIdToDelete(snapshotId)
        setIsDialogueVisible(true)
    }

    const getFirstKey = (obj: Record<string, any>): string | undefined => {
        const keys = Object.keys(obj)
        return keys.length > 0 ? keys[0] : undefined
    }

    const notifyEventReported = (classification: string) =>
        toast.success(`${classification} reported!`, {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    // Helper to notify user of successful image upload
    const notifyImageUploadSuccess = () =>
        toast.success('Image uploaded successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    const handleDuplicateSnapshot = async (snapshotId: string) => {
        if (session?.id) {
            try {
                const snapshot = session.snapshots.find((snapshot) => snapshot.id === snapshotId)
                const event = snapshot?.description
                if (event) {
                    const timestamp = new Date()
                    await sessionsAPI.addSnapshot(session.id, timestamp, event)
                    notifyEventReported(getFirstKey(event) || '')
                }
            } catch (error) {
                console.error('Error logging data:', error)
            }
        } else {
            alert('No session ID provided')
        }
    }

    const handleFileUpload = async (snapshotId: string, file: File | null) => {
        if (file && session?.id) {
            try {
                await imageUpload.uploadImage(session.id, snapshotId, file)
                notifyImageUploadSuccess()
            } catch (error) {
                toast.error('Image upload failed', { position: 'top-right', autoClose: 2000 })
                console.error('Error uploading image:', error)
            }
        } else {
            alert(file)
            alert(session)
        }
        return true
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToDelete) {
            setLoading(true)
            await sessionsAPI.deleteSnapshot(sessionIdToDelete)
            setLoading(false)
            setSessionIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const deleteImageHandler = async (snapshotId: string, imageId: string) => {
        if (session && session.id) await imageUpload.deleteImage(session.id, snapshotId, imageId)
        return true
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedTitle(e.target.value)
    }

    useEffect(() => {
        const scroll = () => {
            if (scrollToEvent && snapshotRefs.current[scrollToEvent]) {
                setIsCollapsed(false)
                const element = snapshotRefs.current[scrollToEvent]
                const offset = 200

                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY
                    const offsetPosition = elementPosition - offset

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    })
                }
            }
        }

        if (scrollToEvent && snapshotRefs.current[scrollToEvent]) {
            setIsCollapsed(false)
        }

        setTimeout(scroll, 1000)
    }, [scrollToEvent])

    useEffect(() => {
        setIsCollapsed(!displayEventList)
    }, [displayEventList])

    const Header = useMemo(() => {
        const handleEditSessionTile = async () => {
            if (session) {
                await sessionsAPI.editSession(session.id, editedTitle, session.softwareVersions)
                setIsEditingTitle(false)
            }
        }

        return (
            <div className="tile-header">
                {isEditingTitle ? (
                    <div className="title-edit">
                        <input type="text" value={editedTitle} onChange={handleTitleChange} />
                        <button onClick={handleEditSessionTile} title="Save">
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button onClick={() => setIsEditingTitle(false)} title="Cancel">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                ) : (
                    <div className="title">
                        {session?.title}
                        <button
                            className="edit-button"
                            onClick={() => setIsEditingTitle(true)}
                            title="Edit Title"
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    </div>
                )}
            </div>
        )
    }, [isEditingTitle, editedTitle, session, sessionsAPI])

    if (!session) return <></>

    const display = { display: isCollapsed ? 'none' : 'block' }

    const sortedSnapshots = isDescending ? [...session.snapshots].reverse() : session.snapshots

    return (
        <>
            {isDialogueVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to delete this event?</div>}
                    callback={deleteHandler}
                    loading={loading}
                />
            )}
            <div className="session-tile">
                <div className="body">
                    <SessionMetadata
                        header={Header}
                        session={session}
                        isUtc={isUtc}
                        // todo: jcg this approach is fragile since it's dependent on upstream reqs for session view
                        disableMargin={showSessionTools}
                    />

                    {customComponent}

                    <div className="session-data-wrapper">
                        <div className="bottom-controls">
                            {showSessionTools && (
                                <button onClick={hideSnapshot}>
                                    {isCollapsed ? 'Show' : 'Hide'}
                                </button>
                            )}
                            <div className="controls">
                                {onResume ? (
                                    <button
                                        className="resume-button"
                                        onClick={() => onResume(session.id)}
                                        title="Resume session"
                                    >
                                        <FontAwesomeIcon icon={faCirclePlay} />
                                    </button>
                                ) : null}
                                {onDelete ? (
                                    <button
                                        className="delete-button"
                                        onClick={() => onDelete(session.id)}
                                        title="Delete session"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        <div style={display} className="snapshots">
                            {sortedSnapshots.length > 0 ? (
                                sortedSnapshots.map((snapshot, index) => {
                                    let previousSnapshotTimestamp =
                                        index > 0
                                            ? sortedSnapshots[index - 1].initiationTimestamp
                                            : null

                                    if (isDescending) {
                                        previousSnapshotTimestamp =
                                            index < sortedSnapshots.length - 1
                                                ? sortedSnapshots[index + 1].initiationTimestamp
                                                : null
                                    }
                                    return (
                                        <TileView
                                            key={index}
                                            session={session}
                                            snapshot={snapshot}
                                            previousTimestamp={previousSnapshotTimestamp}
                                            handleDeleteSnapshot={handleDeleteSnapshot}
                                            handleDuplicateSnapshot={
                                                enableDuplicate
                                                    ? handleDuplicateSnapshot
                                                    : undefined
                                            }
                                            isUtc={isUtc}
                                            scrollToEvent={scrollToEvent}
                                            snapshotRefs={snapshotRefs}
                                            isDescending={isDescending}
                                            handleFileUpload={handleFileUpload}
                                            deleteImage={deleteImageHandler}
                                        />
                                    )
                                })
                            ) : (
                                <div className="log-entry">
                                    <div>No snapshots available</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionTile
