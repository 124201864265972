import { AppDispatch } from '../../data/store'
import { ApolloClient, NormalizedCacheObject, gql, ApolloError } from '@apollo/client'

import {
    EquipmentAddData,
    EquipmentEditData,
    EquipmentDeleteData,
    EquipmentEditDevices,
} from './equipment/types'

import {
    fetchDataRequest as fetchEquipmentRequest,
    fetchDataFailure as fetchEquipmentFailure,
    fetchEquipmentSuccess,
    addEquipmentSuccess,
    editEquipmentSuccess,
    deleteEquipmentSuccess,
} from '../../data/reducers/equipment'

function getSliceActions() {
    return {
        request: fetchEquipmentRequest,
        failure: fetchEquipmentFailure,
        fetchSuccess: fetchEquipmentSuccess,
        addSuccess: addEquipmentSuccess,
        editSuccess: editEquipmentSuccess,
        deleteSuccess: deleteEquipmentSuccess,
    }
}

// ------------------------------------------------------------------
// 2) FETCH Equipment
// ------------------------------------------------------------------
export const fetchEquipment = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const { request, failure, fetchSuccess } = getSliceActions()

    // GraphQL
    const GET_EQUIPMENT = gql`
        query GetEquipment {
            equipment {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
                type
                devices
            }
        }
    `
    dispatch(request())

    try {
        const response = await client.query({
            query: GET_EQUIPMENT,
            fetchPolicy: 'no-cache',
        })
        dispatch(fetchSuccess(response.data.equipment))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(failure({ message }))
    }
}

// ------------------------------------------------------------------
// 3) ADD Equipment
// ------------------------------------------------------------------
export const addEquipment = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    { type, name, description }: EquipmentAddData
) => {
    const { request, failure, addSuccess } = getSliceActions()

    const ADD_EQUIPMENT = gql`
        mutation AddEquipment($type: EquipmentType!, $name: String!, $description: String) {
            addEquipment(type: $type, name: $name, description: $description) {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
                type
            }
        }
    `
    dispatch(request())

    try {
        const response = await client.mutate({
            mutation: ADD_EQUIPMENT,
            variables: { type, name, description },
        })
        dispatch(addSuccess(response.data.addEquipment))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(failure({ message }))
    }
}

// ------------------------------------------------------------------
// 4) EDIT Equipment
// ------------------------------------------------------------------
export const editEquipment = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    { type, id, name, description, softwareVersions }: EquipmentEditData
) => {
    const { request, failure, editSuccess } = getSliceActions()

    const EDIT_EQUIPMENT = gql`
        mutation EditEquipment(
            $id: ID!
            $type: EquipmentType!
            $name: String!
            $description: String
            $softwareVersions: [SoftwareVersionInput]
        ) {
            editEquipment(
                id: $id
                type: $type
                name: $name
                description: $description
                softwareVersions: $softwareVersions
            ) {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
                type
                devices
            }
        }
    `

    dispatch(request())
    try {
        const response = await client.mutate({
            mutation: EDIT_EQUIPMENT,
            variables: { id, type, name, description, softwareVersions },
        })
        dispatch(editSuccess(response.data.editEquipment))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(failure({ message }))
    }
}

// ------------------------------------------------------------------
// 5) DELETE Equipment
// ------------------------------------------------------------------
export const deleteEquipment = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    { type, id }: EquipmentDeleteData
) => {
    const { request, failure, deleteSuccess } = getSliceActions()

    const DELETE_EQUIPMENT = gql`
        mutation DeleteEquipment($id: ID!) {
            deleteEquipment(id: $id) {
                id
                name
                description
                type
            }
        }
    `
    dispatch(request())

    try {
        const response = await client.mutate({
            mutation: DELETE_EQUIPMENT,
            variables: { id },
        })
        dispatch(deleteSuccess(response.data.deleteEquipment))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(failure({ message }))
    }
}

export const editDevices = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    { type, equipmentId, deviceIds }: EquipmentEditDevices
) => {
    const { request, failure, editSuccess } = getSliceActions()

    const EDIT_DEVICES = gql`
        mutation EditDevices($equipmentId: ID!, $deviceIds: [ID!]!) {
            editDevices(equipmentId: $equipmentId, deviceIds: $deviceIds) {
                id
                name
                description
                softwareVersions {
                    name
                    version
                }
                type
                devices
            }
        }
    `

    dispatch(request())
    try {
        const response = await client.mutate({
            mutation: EDIT_DEVICES,
            variables: { equipmentId, deviceIds },
        })

        // Reuse "editSuccess" since we are effectively editing the equipment object
        dispatch(editSuccess(response.data.editDevices))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(failure({ message }))
    }
}
