import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip, faPlus, faXmarkSquare, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { DeviceData, EquipmentData } from '../../data/types'
import { RootState } from '../../data/store'
import { useNetworkManager } from '../../network/networkManager'
import './deviceSelection.scss'
import { ConfirmationPopup } from '../misc/popup'

interface DeviceSelectionProps {
    equipment: EquipmentData
    type: 'Machine' | 'Implement'
    enableModification: boolean
}

function DeviceSelection({ equipment, type, enableModification }: DeviceSelectionProps) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const groupId = searchParams.get('groupId') || 'defaultGroupId'

    const [devices, setDevices] = useState<DeviceData[]>([])
    const deviceData = useSelector((state: RootState) => state.devices.data)
    const { equipment: equipmentApi } = useNetworkManager()

    // Track which devices are assigned to the equipment
    const [assignedDevices, setAssignedDevices] = useState<string[]>(equipment.devices || [])

    // Slide-open logic for adding new devices
    const [isOpen, setIsOpen] = useState(false)
    const contentRef = useRef<HTMLDivElement>(null)

    const [deviceIdToRemove, setDeviceIdToRemove] = useState<string | null>(null)
    const [isDialogueVisible, setIsDialogueVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    // Track which device's menu is open
    const [menuOpen, setMenuOpen] = useState<string | null>(null)

    useEffect(() => {
        // Populate local state with device data from Redux
        setDevices(deviceData)
    }, [deviceData])

    // Keep assignedDevices in sync if equipment changes from outside
    useEffect(() => {
        setAssignedDevices(equipment.devices || [])
    }, [equipment])

    const handleToggle = () => {
        setIsOpen((prev) => !prev)
    }

    // Called whenever we change the device list locally
    // Also calls networkManager to save changes on the server
    const onDeviceListChange = (alteredDeviceList: string[]) => {
        equipmentApi.editDevices({
            type,
            equipmentId: equipment.id,
            deviceIds: alteredDeviceList,
        })
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && deviceIdToRemove) {
            setLoading(true)
            const newAssignedDevices = assignedDevices.filter((d) => d !== deviceIdToRemove)
            // setAssignedDevices(newAssignedDevices)
            onDeviceListChange(newAssignedDevices)
            setLoading(false)
        }
        setDeviceIdToRemove(null)
        setIsDialogueVisible(false)
    }

    // Show confirmation popup for the device to remove
    const promptRemoveDevice = (devId: string) => {
        setDeviceIdToRemove(devId)
        setIsDialogueVisible(true)
        setMenuOpen(null)
    }

    // Add a device (click from the slide-open area)
    const handleAddDevice = (devId: string) => {
        const newAssignedDevices = [...assignedDevices, devId]
        // setAssignedDevices(newAssignedDevices)
        onDeviceListChange(newAssignedDevices)
        setIsOpen(false)
    }

    // Toggle the context menu for a specific device
    const toggleMenu = (devId: string, event: React.MouseEvent) => {
        event.stopPropagation() // So we don't click through
        setMenuOpen(menuOpen === devId ? null : devId)
    }

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !(event.target as HTMLElement).closest('.context-menu') &&
                !(event.target as HTMLElement).closest('.context-menu-icon')
            ) {
                setMenuOpen(null)
            }
        }
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleViewDevice = (deviceId: string) => {
        navigate(`/devices?groupId=${groupId}&deviceSelected=${deviceId}`)
    }

    // Helper if you have multiple actions in the menu
    const handleMenuAction = (action: () => void, event: React.MouseEvent) => {
        event.stopPropagation()
        action()
        setMenuOpen(null)
    }

    return (
        <div className="equipment-devices">
            <div key={equipment.id} className="device-tile">
                <div className="device-body">
                    <div className="device-metadata">
                        <div className="title">Devices</div>

                        <div className="devices">
                            {/* Assigned Devices */}
                            {assignedDevices.length > 0 ? (
                                assignedDevices.map((deviceId) => {
                                    const dev = devices.find((d) => d.id === deviceId)
                                    if (!dev) return null

                                    return (
                                        <div key={deviceId} className="assigned-device-rectangle">
                                            <FontAwesomeIcon
                                                icon={faMicrochip}
                                                className="device-icon"
                                            />
                                            <div className="device-text">
                                                <div className="device-name">
                                                    {dev.name || '<No Name>'}
                                                </div>
                                                <div className="device-description">
                                                    {dev.description}
                                                </div>
                                            </div>

                                            {/* Show the context-menu icon only if hovered over */}
                                            <div
                                                className="context-menu-icon"
                                                onClick={(e) => toggleMenu(deviceId, e)}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </div>

                                            {/* If this device's menu is open, render the context menu */}
                                            {menuOpen === deviceId && (
                                                <div className="context-menu">
                                                    <button
                                                        onClick={(e) =>
                                                            handleMenuAction(
                                                                () => handleViewDevice(deviceId),
                                                                e
                                                            )
                                                        }
                                                    >
                                                        View Device
                                                    </button>
                                                    {enableModification && (
                                                        <button
                                                            onClick={(e) =>
                                                                handleMenuAction(
                                                                    () =>
                                                                        promptRemoveDevice(
                                                                            deviceId
                                                                        ),
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            Remove Device
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })
                            ) : (
                                <>No Devices</>
                            )}

                            {/* "Add Device" tile toggles the slide-open device list */}
                            {enableModification && (
                                <div
                                    className="assigned-device-rectangle new-device"
                                    onClick={handleToggle}
                                >
                                    <div title={isOpen ? 'Cancel' : 'Connect a Device'}>
                                        {isOpen ? (
                                            <div className="device-name">
                                                <FontAwesomeIcon
                                                    icon={faXmarkSquare}
                                                    className="device-icon"
                                                />
                                                Cancel
                                            </div>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="device-icon"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faMicrochip}
                                                    className="device-icon"
                                                    style={{ marginRight: '0' }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Slide-open container for unassigned devices */}
                {isOpen && (
                    <div className="slide-open">
                        <div className="device-list" ref={contentRef}>
                            {/* Filter once and store in a variable */}
                            {(() => {
                                const unassignedDevices = devices
                                    .filter((dev) => !assignedDevices.includes(dev.id)) // Exclude assigned devices
                                    .filter((dev) => !dev.equipment) // Exclude devices with equipment
                                    .filter((dev) => dev.groups && dev.groups.length > 0) // Remove devices without a group
                                    .filter((dev) =>
                                        dev.groups?.some((group) => group.id === groupId)
                                    ) // Ensure it belongs to `groupId`

                                // Conditionally render
                                if (unassignedDevices.length === 0) {
                                    // Fallback message
                                    return (
                                        <p className="no-new-devices">
                                            No new devices to claim/associate.
                                        </p>
                                    )
                                }

                                // Otherwise, map over unassignedDevices
                                return unassignedDevices.map((dev) => (
                                    <div
                                        key={dev.id}
                                        className="assigned-device-rectangle new-device"
                                        onClick={() => handleAddDevice(dev.id)}
                                        title="Add Device"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMicrochip}
                                            className="device-icon"
                                        />
                                        <div className="device-text">
                                            <div className="device-name">
                                                {dev.name || '<No Name>'}
                                            </div>
                                            <div className="device-description">
                                                {dev.description}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            })()}
                        </div>
                    </div>
                )}

                {/* Show confirmation popup if a device is selected for removal */}
                {isDialogueVisible && deviceIdToRemove && (
                    <ConfirmationPopup
                        ChildComponent={<div>Are you sure you want to remove this device?</div>}
                        callback={deleteHandler}
                        loading={loading}
                    />
                )}
            </div>
        </div>
    )
}

export { DeviceSelection }
