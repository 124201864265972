import { ApolloClient } from '@apollo/client'
import { NormalizedCacheObject } from '@apollo/client'

import { AppDispatch } from '../data/store'
import { CreateApolloClient } from './apolloClient'
import { useAppDispatch } from '../data/dispatchContext'

import * as equipmentActions from './graphqlActions/equipmentActions'
import * as userActions from './graphqlActions/userActions'
import * as sessionActions from './graphqlActions/sessionActions'
import * as groupActions from './graphqlActions/groupsActions'
import * as deviceActions from './graphqlActions/devicesActions'

import { useAuth0 } from '@auth0/auth0-react'

const baseURL = process.env.REACT_APP_BACKEND_SERVER_URL

function withClientAndDispatch<Args extends any[]>(
    client: ApolloClient<NormalizedCacheObject>,
    action: (
        client: ApolloClient<NormalizedCacheObject>,
        dispatch: AppDispatch,
        ...args: Args
    ) => Promise<any>,
    dispatch: AppDispatch
): (...args: Args) => Promise<any> {
    return (...args: Args) => {
        return action(client, dispatch, ...args)
    }
}

export const useNetworkManager = () => {
    const { getAccessTokenSilently } = useAuth0()

    const dispatch = useAppDispatch()
    const equipmentClient = CreateApolloClient(`${baseURL}/equipment`, getAccessTokenSilently)
    const userClient = CreateApolloClient(`${baseURL}/users`, getAccessTokenSilently)
    const sessionsClient = CreateApolloClient(`${baseURL}/sessions`, getAccessTokenSilently)
    const groupsClient = CreateApolloClient(`${baseURL}/groups`, getAccessTokenSilently)
    const devicesClient = CreateApolloClient(`${baseURL}/devices`, getAccessTokenSilently)

    return {
        equipment: {
            fetchEquipment: withClientAndDispatch(
                equipmentClient,
                equipmentActions.fetchEquipment,
                dispatch
            ),
            addEquipment: withClientAndDispatch(
                equipmentClient,
                equipmentActions.addEquipment,
                dispatch
            ),
            editEquipment: withClientAndDispatch(
                equipmentClient,
                equipmentActions.editEquipment,
                dispatch
            ),
            deleteEquipment: withClientAndDispatch(
                equipmentClient,
                equipmentActions.deleteEquipment,
                dispatch
            ),
            editDevices: withClientAndDispatch(
                equipmentClient,
                equipmentActions.editDevices,
                dispatch
            ),
        },
        user: {
            getUser: withClientAndDispatch(userClient, userActions.fetchUser, dispatch),
            getUsers_management: withClientAndDispatch(
                userClient,
                userActions.fetchUsers,
                dispatch
            ),
        },
        groups: {
            getGroups: withClientAndDispatch(groupsClient, groupActions.fetchGroups, dispatch),
            addGroup: withClientAndDispatch(groupsClient, groupActions.addGroup, dispatch),
            deleteGroup: withClientAndDispatch(groupsClient, groupActions.deleteGroup, dispatch),
            addUser: withClientAndDispatch(groupsClient, groupActions.addUser, dispatch),
            removeUser: withClientAndDispatch(groupsClient, groupActions.removeUser, dispatch),
            getGroupProfile: withClientAndDispatch(
                groupsClient,
                groupActions.getGroupProfile,
                dispatch
            ),
        },
        sessions: {
            fetchSessions: withClientAndDispatch(
                sessionsClient,
                sessionActions.fetchSessions,
                dispatch
            ),
            addSession: withClientAndDispatch(sessionsClient, sessionActions.addSession, dispatch),
            editSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.editSession,
                dispatch
            ),
            stopSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.stopSession,
                dispatch
            ),
            resumeSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.resumeSession,
                dispatch
            ),
            deleteSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.deleteSession,
                dispatch
            ),
            addSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.addSnapshot,
                dispatch
            ),
            editSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.editSnapshot,
                dispatch
            ),
            deleteSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.deleteSnapshot,
                dispatch
            ),
            downloadFile: withClientAndDispatch(
                sessionsClient,
                sessionActions.downloadFile,
                dispatch
            ),
        },
        devices: {
            getDevices: withClientAndDispatch(devicesClient, deviceActions.fetchDevices, dispatch),
            updateDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.updateDevice,
                dispatch
            ),
            deleteDeviceFile: withClientAndDispatch(
                devicesClient,
                deviceActions.deleteDeviceFile,
                dispatch
            ),
            deleteDeviceImage: withClientAndDispatch(
                devicesClient,
                deviceActions.deleteDeviceImage,
                dispatch
            ),
            downloadDeviceFile: withClientAndDispatch(
                devicesClient,
                deviceActions.downloadDeviceFile,
                dispatch
            ),
            commandDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.commandDevice,
                dispatch
            ),
            deleteDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.deleteDevice,
                dispatch
            ),
            getAllDevices: withClientAndDispatch(
                devicesClient,
                deviceActions.getAllDevices,
                dispatch
            ),
            editDeviceGroups: withClientAndDispatch(
                devicesClient,
                deviceActions.editDeviceGroups,
                dispatch
            ),
        },
    }
}
